import {
  DISTRICT_COMMON,
  SCHOOL_COMMON,
  AUTHOR,
} from '@edulastic/constants/const/userPermissions'

export const getPermissionLevel = (permissions = []) => {
  if (permissions.includes(DISTRICT_COMMON)) {
    return DISTRICT_COMMON
  }

  if (permissions.includes(SCHOOL_COMMON)) {
    return SCHOOL_COMMON
  }

  return undefined
}

export const teacherPermissionsMap = {
  [DISTRICT_COMMON]: 'District & School Common',
  [SCHOOL_COMMON]: 'School Common',
  [AUTHOR]: 'Author',
}
