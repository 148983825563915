import React, { useLayoutEffect, useRef, useState } from 'react'
import { Popover } from 'antd'
import { Overlay } from '../../../widgets/Passage/components/styled-components'

const PopoverContainer = ({
  styles,
  content,
  visible = true,
  ...popoverProps
}) => {
  const popoverRef = useRef(null)
  const [, setPopoverWidth] = useState(0)

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (popoverRef.current) {
        setPopoverWidth(popoverRef.current.offsetWidth)
      }
    })
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <div style={{ ...styles, width: 'inherit', height: 'inherit' }}>
        <Popover
          overlayClassName="content-clarifier-popover"
          placement={popoverProps.placement || 'bottom'}
          trigger="click"
          visible={visible}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          content={<div ref={popoverRef}>{content}</div>}
          {...popoverProps}
        />
      </div>
      {/* Overlay ensures popover closes when selecting highlighted text,
          We are already using the pattern in PassageContent.js:78 */}
      <Overlay />
    </>
  )
}

export default PopoverContainer
