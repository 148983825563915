import { isEmpty } from 'lodash'
import { addClarifierPopoverWidth, CLARIFIER_QUERY_SELECTOR } from './constants'

export const getPopoverStyles = (textSelectionPositionData) => {
  if (isEmpty(textSelectionPositionData)) {
    return null
  }

  const {
    selectionTop,
    selectionLeft,
    selectionWidth,
    selectionHeight,
    windowWidth,
  } = textSelectionPositionData

  const style = { position: 'fixed', zIndex: 9999 }

  style.left = selectionLeft + selectionWidth / 2
  style.top = selectionTop + selectionHeight + 12

  if (style.left < 0) {
    style.left = 0
  } else if (style.left + addClarifierPopoverWidth > windowWidth) {
    style.left = windowWidth - addClarifierPopoverWidth
  }

  if (style.top < 0) {
    style.top = selectionTop + selectionHeight + 5
  }

  return style
}

export function isSelectionOverlappingClarifier(selection) {
  if (!selection.rangeCount) return false

  const range = selection.getRangeAt(0)
  const startNode = range.startContainer
  const endNode = range.endContainer

  // Case 1: Start node is inside a clarifier span
  const startClarifier =
    startNode.nodeType === 3
      ? startNode.parentElement.closest(CLARIFIER_QUERY_SELECTOR)
      : startNode.closest?.(CLARIFIER_QUERY_SELECTOR)

  // Case 2: End node is inside a clarifier span
  const endClarifier =
    endNode.nodeType === 3
      ? endNode.parentElement.closest(CLARIFIER_QUERY_SELECTOR)
      : endNode.closest?.(CLARIFIER_QUERY_SELECTOR)

  // Case 3: Entire selection **contains** a clarifier span
  const temp = document.createElement('div')
  temp.appendChild(range.cloneContents())
  const containsClarifier = temp.querySelector?.(CLARIFIER_QUERY_SELECTOR)
  temp.remove()

  return !!startClarifier || !!endClarifier || containsClarifier
}

export const isClarifierElement = (element) => {
  return element?.matches && element.matches(CLARIFIER_QUERY_SELECTOR)
}

export function wrapStimulusForClarifierSource(html, source) {
  const div = document.createElement('div')
  div.innerHTML = html
  div.id = `for-clarifier-${source}`

  return div.outerHTML
}

export const getWrapperClarifierSource = (node) => {
  let element = node.startContainer
  if (element.nodeType === Node.TEXT_NODE) {
    element = element.parentElement
  }
  return element?.closest('[id^="for-clarifier-"]')
}
