import styled from 'styled-components'
import { Divider, Modal } from 'antd'
import {
  accessibilityColors,
  addClarifierHoverBackground,
  addClarifierModalTitleColor,
  black,
  blue,
  deleteClarifierButtonColor,
  fadedRed,
  gray600,
  placeholderTextColor,
  white,
} from '@edulastic/colors'
import { EduButton, Stimulus } from '@edulastic/common'
import { CLARIFIER_QUERY_SELECTOR } from './constants'
import { Stimulus as ReviewStimulus } from '../../../author/TestPage/components/Review/components/ReviewItem/MainInfo/styled'

export const StyledAddClarifierCard = styled.div`
  margin: -12px -16px;
  padding: 12px 16px;

  &:hover {
    background: ${addClarifierHoverBackground};

    p {
      color: ${white};
    }

    svg {
      path {
        fill: ${white};
      }
    }
  }
`

export const StyledAddClarifierCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const StyledAddClarifierCardText = styled.p`
  font-family: Open Sans;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${black};
`
export const StyledAddClarifierCardDescriptionText = styled.p`
  font-family: Open Sans;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${placeholderTextColor};
`
export const StyledAddClarifierCardIconContainer = styled.div`
  align-self: flex-start;
`
export const StyledAddClarifierContentModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      svg {
        height: 20px;
        width: 20px;
        fill: ${black};
      }
    }
  }

  .ant-modal-body {
    padding: 25px;
    padding-top: 10px;
  }

  .ant-modal-footer {
    border: none !important;
    padding: 10px 15px 25px 15px !important;
  }
`
export const ClarifierModalTitle = styled.p`
  font-family: Open Sans;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${addClarifierModalTitleColor};
`

export const StyledAddClarifierContentModalWordSelectedTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
`
export const StyledAddClarifierContentModalWordSelectedText = styled.span`
  font-family: Open Sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${gray600};
  margin-right: 6px;
`

export const StyledAddEditClarifierSelectionStimulus = styled(ReviewStimulus)`
  margin-top: 0;
  padding-top: 0;
`

export const StyledViewClarifierSelectionStimulus = styled(ReviewStimulus)`
  margin-top: 5px;
  margin-bottom: 16px;
  padding-top: 0;
  padding-left: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & *:not(.edu) {
    background: transparent !important;
    font-family: ${(props) => props.theme.defaultFontFamily} !important;
    font-size: 18px !important;
    font-weight: bold !important;
  }
  * {
    font-size: 18px !important;
    font-weight: bold !important;
  }

  font-size: 18px !important;
  font-weight: bold !important;
`

export const StyledDeleteClarifierModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      svg {
        height: 20px;
        width: 20px;
        fill: ${black};
      }
    }
  }

  .ant-modal-body {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ant-modal-footer {
    border: none !important;
    //padding: 10px 15px 25px 15px !important;
  }
`

export const DeleteClarifierModalBodyText = styled.div`
  font-weight: 600;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
`

export const StyledModalDivider = styled(Divider)`
  margin: 6px -16px;
  width: auto;
`

export const StyledActionButtonFooterContainer = styled.div`
  display: flex;
  gap: 0;
  height: 48px;
  margin: -1px -16px -12px;
  min-width: 320px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`

export const StyledActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: background 0.2s ease-in-out;
  flex: 1;

  svg {
    fill: ${({ iconColor }) => iconColor};
  }

  &:hover {
    background: ${({ iconColor }) => iconColor};

    svg {
      fill: white;
    }

    color: white;
  }
`

export const StyledClarifierWrapper = styled.div`
  ${({ viewEnabled }) =>
    viewEnabled &&
    `${CLARIFIER_QUERY_SELECTOR} {
      border-bottom: 2px dotted ${blue};
      cursor: pointer;
      display: inline-block;
    }`}
`

export const StyledFroalaWrapper = styled.div`
  .fr {
    &-box {
      background: ${({ charLimitExceeded }) =>
        charLimitExceeded ? fadedRed : undefined};
    }
  }
`

export const StyledWorldLimitExceededText = styled.p`
  padding-top: 5px;
  color: ${accessibilityColors.labels.red.text};
  font-weight: bold;
  text-align: right;
`

export const StyledViewClarifierContainer = styled.div`
  min-width: 200px;
  max-width: 600px;
`

export const StyledClarifierContentStimulus = styled(Stimulus)`
  cursor: default;
  margin-bottom: 1px;
  max-height: 500px;
  overflow: auto;
`

export const StyledDeleteClarifierConfirmationButton = styled(
  EduButton
).attrs((props) => ({ isGhost: true, noHover: true, btnType: null, ...props }))`
  background-color: ${accessibilityColors.theme.red};
  border-color: ${accessibilityColors.theme.red};
  color: ${accessibilityColors.theme.white};

  &:hover {
    background-color: ${deleteClarifierButtonColor};
    border-color: ${deleteClarifierButtonColor};
    color: ${accessibilityColors.theme.white};
  }
`
