import {
  testTypes as testTypesConstants,
  userPermissions as userPermissionTypes,
} from '@edulastic/constants'

export const testTypeGroups = {
  INDIVIDUAL: 'individual',
  COMMON: 'common',
}

export const getDefaultTestTypeForGroup = (testTypeGroup, testTypes) => {
  if (testTypeGroup === testTypeGroups.INDIVIDUAL) {
    return 'assessment'
  }

  return Object.keys(testTypes)[0]
}

export const getPermissionWarningType = (userPermissions, testType) => {
  if (
    userPermissions.includes(
      testTypesConstants.TEST_TYPE_PERMISSIONS_MAP[testType]
    ) ||
    userPermissions.includes(userPermissionTypes.AUTHOR) ||
    userPermissions.includes(userPermissionTypes.CURATOR)
  ) {
    return false
  }
  return testType
}
