import React from 'react'
import { IconAIFileAttachment } from '@edulastic/icons'
import {
  StyledAddClarifierCard,
  StyledAddClarifierCardDescriptionText,
  StyledAddClarifierCardIconContainer,
  StyledAddClarifierCardText,
  StyledAddClarifierCardTextContainer,
} from '../styled'
import PopoverContainer from './PopoverContainer'

const AddClarifierPopover = ({ onAddClarifier, styles }) => {
  const Content = (
    <StyledAddClarifierCard
      data-cy="add-clarifier-popover"
      onMouseDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onClick={(e) => {
        e.stopPropagation()
        onAddClarifier()
      }}
    >
      <StyledAddClarifierCardIconContainer>
        <IconAIFileAttachment width="11" height="14" />
      </StyledAddClarifierCardIconContainer>
      <StyledAddClarifierCardTextContainer>
        <StyledAddClarifierCardText>Add Explainer</StyledAddClarifierCardText>
        <StyledAddClarifierCardDescriptionText>
          Add images, text for students to understand
        </StyledAddClarifierCardDescriptionText>
      </StyledAddClarifierCardTextContainer>
    </StyledAddClarifierCard>
  )

  return (
    <PopoverContainer
      styles={styles}
      content={Content}
      overlayClassName="content-clarifier-popover content-clarifier-popover-hoverable"
    />
  )
}

export default AddClarifierPopover
