import {
  CheckboxLabel,
  CustomModalStyled,
  EduButton,
  TextInputStyled,
  RadioBtn,
  RadioGrp,
} from '@edulastic/common'
import { userPermissions, roleuser, subscriptions } from '@edulastic/constants'
import { Col, Form, Row, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { omit } from 'lodash'
import React, { Component } from 'react'
import { ButtonsContainer, ModalFormItem } from '../../../../../common/styled'
import { getPermissionLevel } from '../helpers'
import { CheckBoxSubLabel } from '../styled'
import { getSubscriptionSelector } from '../../../../Subscription/ducks'

const { DISTRICT_COMMON, SCHOOL_COMMON } = userPermissions
class EditTeacherModal extends Component {
  onSaveTeacher = () => {
    const { form } = this.props
    form.validateFields((err, row = {}) => {
      if (!err) {
        const { data, editTeacher, userOrgId } = this.props

        if (row.enablePermissions && row.permissions === DISTRICT_COMMON) {
          row.permissions = [DISTRICT_COMMON, SCHOOL_COMMON]
        } else if (row.enablePermissions && row.permissions === SCHOOL_COMMON) {
          row.permissions = [SCHOOL_COMMON]
        } else {
          row.permissions = []
        }
        delete row.enablePermissions
        if (!row.password) row = omit(row, ['password'])
        row = omit(row, ['confirmPassword'])
        editTeacher({
          userId: data?._id,
          data: Object.assign(row, {
            districtId: userOrgId,
          }),
        })
        this.onCloseModal()
      }
    })
  }

  handleConfirmPassword = (rule, value, callback) => {
    const { form = {} } = this.props
    const { getFieldValue } = form
    const password = getFieldValue('password')
    const confirmPassword = getFieldValue('confirmPassword')

    if (password !== confirmPassword) return callback('Password does not match')

    callback() // no error
  }

  onCloseModal = () => {
    const { closeModal } = this.props
    closeModal()
  }

  componentDidMount = () => {
    const { loadDistrictTestSettings, userOrgId } = this.props
    loadDistrictTestSettings({
      orgId: userOrgId,
      orgType: 'district',
    })
  }

  render() {
    const {
      modalVisible,
      data: { _source },
      form: { getFieldDecorator, getFieldValue },
      userRole,
      t,
      districtTestSettings,
      subscription: { subType } = {},
    } = this.props
    const permissionLevel = getPermissionLevel(_source?.permissions)
    const disablePermissionForSA =
      permissionLevel === DISTRICT_COMMON &&
      userRole !== roleuser.DISTRICT_ADMIN
    const commonSettingDisabled =
      !districtTestSettings.canTeacherAuthorCommonTests ||
      subType !== subscriptions.SUBSCRIPTION_SUB_TYPES.ENTERPRISE
    return (
      <CustomModalStyled
        visible={modalVisible}
        title={t('users.teacher.editteacher.title')}
        onOk={this.onSaveTeacher}
        onCancel={this.onCloseModal}
        maskClosable={false}
        centered
        footer={[
          <ButtonsContainer>
            <EduButton isGhost onClick={this.onCloseModal}>
              {t('users.teacher.editteacher.nocancel')}
            </EduButton>
            <EduButton onClick={this.onSaveTeacher}>
              {t('users.teacher.editteacher.yesupdate')}
            </EduButton>
          </ButtonsContainer>,
        ]}
      >
        <Row>
          <Col span={24}>
            <ModalFormItem label={t('users.teacher.editteacher.firstname')}>
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    message: t(
                      'users.teacher.editteacher.validations.firstname'
                    ),
                  },
                ],
                initialValue: _source?.firstName,
              })(
                <TextInputStyled
                  placeholder={t('users.teacher.editteacher.enterfirstname')}
                />
              )}
            </ModalFormItem>
          </Col>
          <Col span={24}>
            <ModalFormItem label={t('users.teacher.editteacher.lastname')}>
              {getFieldDecorator('lastName', {
                rules: [
                  {
                    required: true,
                    message: t(
                      'users.teacher.editteacher.validations.lastname'
                    ),
                  },
                ],
                initialValue: _source?.lastName,
              })(
                <TextInputStyled
                  placeholder={t('users.teacher.editteacher.enterlastname')}
                />
              )}
            </ModalFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ModalFormItem label={t('users.teacher.editteacher.email')}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: t('users.teacher.editteacher.validations.email'),
                  },
                  {
                    type: 'email',
                    message: t(
                      'users.teacher.editteacher.validations.invalidemail'
                    ),
                  },
                ],
                initialValue: _source?.email,
              })(
                <TextInputStyled
                  placeholder={t('users.teacher.editteacher.enteremail')}
                />
              )}
            </ModalFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ModalFormItem label={t('users.teacher.editteacher.password')}>
              {getFieldDecorator(
                'password',
                {}
              )(
                <TextInputStyled
                  type="password"
                  placeholder={t('users.teacher.editteacher.enterpassword')}
                  autoComplete="off"
                  data-cy="passwordTextBox"
                />
              )}
            </ModalFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ModalFormItem
              label={t('users.teacher.editteacher.confirmpassword')}
            >
              {getFieldDecorator('confirmPassword', {
                rules: [
                  {
                    validator: this.handleConfirmPassword,
                    message: t(
                      'users.teacher.editteacher.validations.invalidpassword'
                    ),
                  },
                ],
              })(
                <TextInputStyled
                  type="password"
                  autoComplete="off"
                  placeholder={t(
                    'users.teacher.editteacher.enterconfirmpassword'
                  )}
                  data-cy="confirmPasswordTextBox"
                />
              )}
            </ModalFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {getFieldDecorator('isPowerTeacher', {
              initialValue: _source?.isPowerTeacher,
              valuePropName: 'checked',
            })(
              <CheckboxLabel data-cy="powerUserCheckBox" textTransform="none">
                {t('users.teacher.powertools')}
                <CheckBoxSubLabel>
                  Advanced settings while authoring questions
                </CheckBoxSubLabel>
              </CheckboxLabel>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {getFieldDecorator('enablePermissions', {
              initialValue: _source?.permissions.includes(SCHOOL_COMMON),
              valuePropName: 'checked',
            })(
              <CheckboxLabel
                data-cy="commonTestPermissions"
                textTransform="none"
                disabled={disablePermissionForSA || commonSettingDisabled}
              >
                Common Test Permissions
                <span style={{ marginLeft: '10px' }}>
                  {getFieldDecorator('permissions', {
                    initialValue: permissionLevel,
                  })(
                    <RadioGrp>
                      <Tooltip
                        title={
                          userRole !== roleuser.DISTRICT_ADMIN
                            ? t('users.teacher.districtCommonInfo')
                            : ''
                        }
                      >
                        <RadioBtn
                          value={DISTRICT_COMMON}
                          disabled={
                            userRole !== roleuser.DISTRICT_ADMIN ||
                            !getFieldValue('enablePermissions') ||
                            commonSettingDisabled
                          }
                          textTransform="none"
                          data-cy="districtAndSchoolCommonRadio"
                        >
                          District & School
                        </RadioBtn>
                      </Tooltip>
                      <RadioBtn
                        value={SCHOOL_COMMON}
                        disabled={
                          disablePermissionForSA ||
                          !getFieldValue('enablePermissions') ||
                          commonSettingDisabled
                        }
                        textTransform="none"
                        data-cy="onlySchoolCommonRadio"
                      >
                        Only School
                      </RadioBtn>
                    </RadioGrp>
                  )}
                </span>
                <CheckBoxSubLabel>
                  Allow teacher to publish and regrade common test{' '}
                </CheckBoxSubLabel>
              </CheckboxLabel>
            )}
          </Col>
        </Row>
      </CustomModalStyled>
    )
  }
}

const EditTeacherModalForm = Form.create()(EditTeacherModal)

const enhance = connect((state) => ({
  subscription: getSubscriptionSelector(state),
}))
export default enhance(EditTeacherModalForm)
