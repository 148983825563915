import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { reportsApi, dataWarehouseApi } from '@edulastic/api'
import { notification } from '@edulastic/common'
import { getSortedQueryAndQueryStr } from '@edulastic/constants/reportUtils/common'
import { actions } from './actionReducers'
import {
  tableData as tableDataSelector,
  summaryData as summaryDataSelector,
  skillInfoQueryStr as skillInfoQueryStrSelector,
  skillInfo as skillInfoSelector,
} from './selectors'
import { getInterestedCurriculumsSelector } from '../../../../../src/selectors/user'

const { domainAnalysisReportApi } = dataWarehouseApi

function* fetchFiltersDataRequestSaga({ payload }) {
  try {
    const filtersData = yield call(reportsApi.fetchMARFilterData, payload)
    yield put(actions.fetchFiltersDataRequestSuccess({ filtersData }))
  } catch (error) {
    const msg =
      'Error getting filter data. Please try again after a few minutes.'
    notification({ msg })
    yield put(actions.fetchFiltersDataRequestError({ error: msg }))
  }
}

function* fetchSkillInfoRequestSaga({ payload }) {
  try {
    const {
      query: skillQuery,
      queryStr: skillInfoQueryStr,
    } = getSortedQueryAndQueryStr({
      query: payload,
      fieldsToOmit: [
        'curriculumId',
        'standardGrades',
        'domainIds',
        'clusterIds',
        'standardIds',
      ],
      stringifyArrays: false,
    })
    const prevSkillInfoQueryStr = yield select(skillInfoQueryStrSelector)
    const prevSkillInfo = yield select(skillInfoSelector)

    // only call skill info API if query str has changed else return previous skill info
    // this will trigger updates/effects for dependent functions/components
    const skillInfoResponse =
      skillInfoQueryStr !== prevSkillInfoQueryStr
        ? yield call(domainAnalysisReportApi.getSkillInfo, skillQuery)
        : { result: { ...prevSkillInfo } }

    if (skillInfoResponse?.dataSizeExceeded) {
      yield put(
        actions.fetchSkillInfoRequestError({
          error: skillInfoResponse,
        })
      )
      return
    }

    // if curriculum info is not available, fallback to interested curriculums
    const skillInfo = skillInfoResponse.result
    if (!skillInfo.curriculumInfo.length) {
      skillInfo.curriculumInfo = yield select(getInterestedCurriculumsSelector)
    }

    yield put(
      actions.fetchSkillInfoRequestSuccess({ skillInfo, skillInfoQueryStr })
    )
  } catch (error) {
    const msg =
      'Error getting skill info. Please try again after a few minutes.'
    notification({ msg, type: 'error' })
    yield put(actions.fetchSkillInfoRequestError({ error: true }))
  }
}

function* fetchSummaryDataRequestSaga({ payload }) {
  try {
    const summaryApiResponse = yield call(
      domainAnalysisReportApi.getDomainAnalysisSummary,
      payload
    )
    if (summaryApiResponse?.dataSizeExceeded) {
      yield put(
        actions.fetchSummaryDataRequestError({
          error: summaryApiResponse,
        })
      )
      return
    }
    const newSummaryData = summaryApiResponse.result
    const prevSummaryData = yield select(summaryDataSelector)
    if (!payload.requireTotalCount) {
      newSummaryData.rowsCount = prevSummaryData?.rowsCount || 0
    }
    yield put(
      actions.fetchSummaryDataRequestSuccess({
        summaryData: newSummaryData,
      })
    )
  } catch (error) {
    const msg =
      'Error getting chart data. Please try again after a few minutes.'
    notification({ msg, type: 'error' })
    yield put(actions.fetchSummaryDataRequestError({ error: true }))
  }
}

function* fetchTableDataRequestSaga({ payload }) {
  try {
    const tableApiResponse = yield call(
      domainAnalysisReportApi.getDomainAnalysisDetails,
      payload
    )
    if (tableApiResponse.dataSizeExceeded) {
      yield put(
        actions.fetchTableDataRequestError({
          error: true,
        })
      )
      return
    }
    const newtableData = tableApiResponse.result
    const prevTableData = yield select(tableDataSelector)
    if (!payload.requireTotalCount) {
      newtableData.rowsCount = prevTableData?.rowsCount || 0
    }
    yield put(actions.fetchTableDataRequestSuccess({ tableData: newtableData }))
  } catch (error) {
    const msg =
      'Error getting table data. Please try again after a few minutes.'
    notification({ msg, type: 'error' })
    yield put(actions.fetchTableDataRequestError({ error: true }))
  }
}

export default function* watcherSaga() {
  yield all([
    takeLatest(actions.fetchFiltersDataRequest, fetchFiltersDataRequestSaga),
    takeLatest(actions.fetchSkillInfoRequest, fetchSkillInfoRequestSaga),
    takeLatest(actions.fetchSummaryDataRequest, fetchSummaryDataRequestSaga),
    takeLatest(actions.fetchTableDataRequest, fetchTableDataRequestSaga),
  ])
}
