import React, { createContext, useContext } from 'react'

export const ClarifierContext = createContext(undefined)

export const useClarifierContext = () => {
  return useContext(ClarifierContext) || {}
}

const ClarifierProvider = ({ enabled = true, children }) => {
  return (
    <ClarifierContext.Provider
      value={{
        enabled,
      }}
    >
      {children}
    </ClarifierContext.Provider>
  )
}

export default ClarifierProvider
