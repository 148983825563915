export const ANALYZE_BY_KEYS = {
  SCORE: 'score',
  MASTERY_LEVEL: 'masteryLevel',
}

export const ANALYZE_BY_OPTIONS = [
  { key: 'score', title: 'Score' },
  { key: 'masteryLevel', title: 'Mastery Level' },
]

export const SCORE_TYPE_KEYS = {
  SCALED_SCORE: 'scaledScore',
  RAW_SCORE: 'rawScore',
}

export const SCORE_TYPE_OPTIONS = [
  { key: 'scaledScore', title: 'Scaled Score' },
  { key: 'rawScore', title: 'Raw Score' },
]

export const VIEW_BY_KEYS = {
  // TEST: 'test',
  DOMAIN: 'domain',
  CLUSTER: 'cluster',
  STANDARD: 'standard',
}

export const VIEW_BY_OPTIONS = [
  // {key: 'test', title: 'Test'},
  { key: VIEW_BY_KEYS.DOMAIN, title: 'Domain' },
  {
    key: VIEW_BY_KEYS.CLUSTER,
    title: 'Cluster',
  },
  { key: VIEW_BY_KEYS.STANDARD, title: 'Standard' },
]

export const groupInfoMap = /** @type { const } */ ({
  term: {
    dataKey: 'metric.test.termId',
  },
  test: {
    dataKey: 'metric.test._id',
    labelKey: 'metric.test.name',
    descriptionKey: 'metric.test.name',
  },
  [VIEW_BY_KEYS.DOMAIN]: {
    dataKey: 'skill.domainId',
    labelKey: 'skill.domain',
    descriptionKey: 'skill.domainName',
    filterKey: 'domainIds',
  },
  [VIEW_BY_KEYS.CLUSTER]: {
    dataKey: 'skill.clusterId',
    labelKey: 'skill.cluster',
    descriptionKey: 'skill.clusterName',
    filterKey: 'clusterIds',
  },
  [VIEW_BY_KEYS.STANDARD]: {
    dataKey: 'skill.standardId',
    labelKey: 'skill.standard',
    descriptionKey: 'skill.standardName',
    filterKey: 'standardIds',
  },
})

export const reportAtOptions = /** @type { const } */ ({
  [VIEW_BY_KEYS.DOMAIN]: {
    key: 'domainId',
    title: 'Domain',
    ...groupInfoMap[VIEW_BY_KEYS.DOMAIN],
    tableGroupKeys: ['domainId', 'test._id', 'test.termId'],
    chartGroupKeys: [
      {
        groupKeys: [groupInfoMap.domain],
        xAxisKey: 'subGroupLabel',
      },
    ],
  },
  [VIEW_BY_KEYS.CLUSTER]: {
    key: 'clusterId',
    title: 'Cluster',
    ...groupInfoMap[VIEW_BY_KEYS.CLUSTER],
    tableGroupKeys: ['domainId', 'clusterId', 'test._id', 'test.termId'],
    chartGroupKeys: [
      {
        groupKeys: [groupInfoMap.cluster],
        dashed: true,
        xAxisKey: 'subGroupLabel',
        dy: 30,
      },
      {
        groupKeys: [groupInfoMap.domain],
        xAxisKey: 'groupLabel',
        dy: 45,
      },
    ],
  },
  [VIEW_BY_KEYS.STANDARD]: {
    key: 'standardId',
    title: 'Standard',
    ...groupInfoMap[VIEW_BY_KEYS.STANDARD],
    tableGroupKeys: [
      'domainId',
      'clusterId',
      'standardId',
      'test._id',
      'test.termId',
    ],
    chartGroupKeys: [
      {
        groupKeys: [groupInfoMap.standard],
        xAxisKey: 'subGroupLabel',
        dashed: true,
        dy: 30,
      },
      {
        groupKeys: [groupInfoMap.domain, groupInfoMap.cluster],
        xAxisKey: 'groupLabel',
        dashed: true,
        dy: 45,
      },
      { groupKeys: [groupInfoMap.domain] },
    ],
  },
})

export const DEFAULT_FILTERS = {
  // student filters
  termId: '',
  districtIds: [],
  schoolIds: [],
  teacherIds: [],
  grades: [],
  subjects: [],
  courseId: '',
  classIds: [],
  groupIds: [],
  // test filters
  testTermIds: [],
  testGrades: [],
  testSubjects: [],
  assessmentTypes: [],
  tagIds: [],
  testUniqIds: [],
  // demographic filters
  race: '',
  gender: '',
  iepStatus: '',
  frlStatus: '',
  ellStatus: '',
  hispanicEthnicity: '',
  // page filters
  reportAt: VIEW_BY_KEYS.DOMAIN,
  scoreType: SCORE_TYPE_OPTIONS[0].key,
  curriculumId: '',
  standardGrades: [],
  domainIds: [],
  clusterIds: [],
  standardIds: [],
}

export const SKILL_INFO_FILTERS = [
  // student filters
  'termId',
  'districtIds',
  'schoolIds',
  'teacherIds',
  'grades',
  'subjects',
  'courseId',
  'classIds',
  'groupIds',
  // test filters
  'testTermIds',
  'assessmentTypes',
  'testUniqIds',
]

// page standard filters are reset when any of the SKILL_INFO_FILTERS are changed.
// ref: https://github.com/snapwiz/edulastic-poc/blob/f6780d55d77f60ee213fe2fd399226ddfb8cb190/src/client/author/Reports/subPages/dataWarehouseReports/DomainAnalysis/components/PopupFilters.js#L292
export const PAGE_STANDARD_FILTERS = [
  'curriculumId',
  'domainIds',
  'clusterIds',
  'standardIds',
]

export const NEXT_VIEW_BY_KEYS = {
  [VIEW_BY_KEYS.DOMAIN]: VIEW_BY_KEYS.CLUSTER,
  [VIEW_BY_KEYS.CLUSTER]: VIEW_BY_KEYS.STANDARD,
  [VIEW_BY_KEYS.STANDARD]: VIEW_BY_KEYS.STANDARD,
}
