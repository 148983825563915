import { createSelector } from 'reselect'
import { get } from 'lodash'

import { reduxNamespaceKey } from './actionReducers'

const stateSelector = (state) => state.reportReducer[reduxNamespaceKey]

const firstLoad = createSelector(stateSelector, (state) => state.firstLoad)
const selectedTests = createSelector(
  stateSelector,
  (state) => state.selectedTests
)
const loadingFiltersData = createSelector(
  stateSelector,
  (state) => state.loadingFiltersData || state.loadingSkillInfo
)
const loadingSkillInfo = createSelector(
  stateSelector,
  (state) => state.loadingSkillInfo
)
const prevFiltersData = createSelector(
  stateSelector,
  (state) => state.prevFiltersData
)
const filtersData = createSelector(stateSelector, (state) => state.filtersData)
const filtersTabKey = createSelector(
  stateSelector,
  (state) => state.filtersTabKey
)
const filters = createSelector(stateSelector, (state) => state.filters)
const filterTagsData = createSelector(
  stateSelector,
  (state) => state.filterTagsData
)
const selectedFilterTagsData = createSelector(
  stateSelector,
  (state) => state.settings.selectedFilterTagsData
)
const loadingSummaryData = createSelector(
  stateSelector,
  (state) => state.loadingSummaryData
)
const loadingTableData = createSelector(
  stateSelector,
  (state) => state.loadingTableData
)
const skillInfoRequestError = createSelector(
  stateSelector,
  (state) => state.skillInfoRequestError
)
const summaryDataRequestError = createSelector(
  stateSelector,
  (state) => state.summaryDataRequestError
)
const tableDataRequestError = createSelector(
  stateSelector,
  (state) => state.tableDataRequestError
)
const settings = createSelector(stateSelector, (state) => state.settings)
const summaryData = createSelector(stateSelector, (state) => state.summaryData)
const tableData = createSelector(stateSelector, (state) => state.tableData)
const chartFilters = createSelector(
  stateSelector,
  (state) => state.chartFilters
)

const error = createSelector(stateSelector, (state) => state.error)

const selectedPerformanceBand = createSelector(filtersData, (data) => {
  const { scaleInfo = [], testSettings = {} } = get(data, 'data.result', {})
  const profileId = testSettings.testTypesProfile?.standardProficiency?.common
  return (
    scaleInfo.find((pb) => pb._id === profileId) ||
    scaleInfo[0] || { scale: [] }
  )
})

const defaultProfileId = createSelector(filtersData, (data) => {
  const { testSettings = {} } = get(data, 'data.result', {})
  return testSettings.testTypesProfile?.standardProficiency?.common
})

const skillInfoQueryStr = createSelector(
  stateSelector,
  (state) => state.skillInfoQueryStr
)
const skillInfo = createSelector(stateSelector, (state) => state.skillInfo)

export {
  firstLoad,
  selectedTests,
  loadingFiltersData,
  loadingSkillInfo,
  prevFiltersData,
  filtersData,
  filtersTabKey,
  filters,
  filterTagsData,
  selectedFilterTagsData,
  loadingSummaryData,
  loadingTableData,
  skillInfoRequestError,
  summaryDataRequestError,
  tableDataRequestError,
  settings,
  summaryData,
  tableData,
  chartFilters,
  error,
  selectedPerformanceBand,
  defaultProfileId,
  skillInfoQueryStr,
  skillInfo,
}
