import React, { createContext, useContext, useMemo, useRef } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import ClarifiersActionHandler from './ClarifiersActionHandler'
import { useClarifierContext } from './ClarifierProvider'
import { isFeatureContentClarifierEnabledSelector } from '../../../../author/src/selectors/user'

export const ClarifierAccessContext = createContext(undefined)

export const useClarifierAccessContext = () => {
  return useContext(ClarifierAccessContext) || {}
}

const ClarifierAccessProvider = ({
  isEditable,
  isViewOnly,
  addClarifier,
  updateClarifier,
  deleteClarifier,
  getClarifierContent,
  children,
  showClarifiersToUser,
  containerKey,
  canAddMoreClarifier,
}) => {
  const allowedElements = useMemo(() => [], [])
  const containerRef = useRef()
  const clarifierContext = useClarifierContext()
  const enabled = clarifierContext.enabled && showClarifiersToUser

  const registerElement = (element) => {
    if (element && !allowedElements.includes(element)) {
      allowedElements.push(element)
    }
  }

  const removeElement = (element) => {
    const index = allowedElements.indexOf(element)

    if (index > -1) {
      allowedElements.splice(index, 1)
    }
  }

  const getAllowedElements = () => allowedElements

  return (
    <ClarifierAccessContext.Provider
      value={{
        getAllowedElements,
        registerClarifierAllowedElement: registerElement,
        removeClarifierAllowedElement: removeElement,
        isEditable,
        isViewOnly,
        enabled,
      }}
    >
      {enabled && (
        <ClarifiersActionHandler
          addClarifier={addClarifier}
          updateClarifier={updateClarifier}
          deleteClarifier={deleteClarifier}
          getClarifierContent={getClarifierContent}
          isEditable={isEditable}
          isViewOnly={isViewOnly}
          containerRef={containerRef}
          key={containerKey}
          canAddMoreClarifier={canAddMoreClarifier}
        />
      )}
      <div
        style={{
          display: 'contents',
        }}
        ref={containerRef}
      >
        {children}
      </div>
    </ClarifierAccessContext.Provider>
  )
}

const EnhancedClarifierAccessProvider = compose(
  connect(
    (state) => ({
      showClarifiersToUser: isFeatureContentClarifierEnabledSelector(state),
      forceUpdate: Date.now(),
    }),
    {}
  )
)(ClarifierAccessProvider)

export default EnhancedClarifierAccessProvider
