import React, { useEffect, useRef } from 'react'
import { useClarifierAccessContext } from './ClarifierAccessProvider'
import { StyledClarifierWrapper } from '../styled'
import { wrapStimulusForClarifierSource } from '../utils'

export const useStimulusWrapper = () => {
  const { enabled } = useClarifierAccessContext()

  const wrapStimulusForSource = enabled
    ? (stimulus, source) => wrapStimulusForClarifierSource(stimulus, source)
    : (f) => f

  return {
    wrapStimulusForClarifierSource: wrapStimulusForSource,
  }
}

const ClarifierWrapperContainer = ({ children }) => {
  const {
    registerClarifierAllowedElement,
    removeClarifierAllowedElement,
    enabled: clarifierEnabled,
    isEditable,
    isViewOnly,
  } = useClarifierAccessContext()
  const ref = useRef()

  useEffect(() => {
    if (ref.current && isEditable) {
      registerClarifierAllowedElement(ref.current)
      return () => removeClarifierAllowedElement(ref.current)
    }
  }, [ref.current])

  if (clarifierEnabled) {
    return (
      <StyledClarifierWrapper ref={ref} viewEnabled={isEditable || isViewOnly}>
        {children}
      </StyledClarifierWrapper>
    )
  }

  return children
}

export default ClarifierWrapperContainer
