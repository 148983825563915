import React, { PureComponent } from 'react'
import { Row, Col, Spin } from 'antd'
import PropTypes from 'prop-types'
import {
  cloneDeep,
  get,
  uniq as _uniq,
  keyBy,
  set,
  findIndex,
  isEmpty,
  flatten,
  isArray,
  groupBy,
  last,
} from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import produce from 'immer'
import qs from 'qs'
import {
  Paper,
  withWindowSizes,
  notification,
  MainContentWrapper,
  EduIf,
} from '@edulastic/common'
import { withNamespaces } from '@edulastic/localization'
import { roleuser } from '@edulastic/constants'
import {
  ITEM_GROUP_DELIVERY_TYPES,
  ITEM_GROUP_TYPES,
  statusConstants,
} from '@edulastic/constants/const/test'
import { segmentApi, testItemsApi } from '@edulastic/api'
import PreviewModal from '../../../../../src/components/common/PreviewModal'
import HeaderBar from '../HeaderBar/HeaderBar'
import {
  getItemsSubjectAndGradeSelector,
  getAuthoringTestItemsSelector,
  setTestItemsAction,
} from '../../../AddItems/ducks'
import { getAdaptiveSettingLabel, getStandardsSelector } from '../../ducks'
import {
  setTestDataAction,
  previewCheckAnswerAction,
  previewShowAnswerAction,
  getDefaultThumbnailSelector,
  updateDefaultThumbnailAction,
  getTestItemsSelector,
  getAutoSelectItemsLoadingStatusSelector,
  showGroupsPanelSelector,
  getTestsUpdatedSelector,
  hasSectionsSelector,
  isDefaultTestSelector,
  getItemGroupsSelector,
  getAllTagsAction,
  MAX_ITEMS_DELIVER_COUNT,
  getAdaptiveDeliverySelector,
  replaceRestrictedItemAction,
  getIsItemPreviewModalVisibleSelector,
  setModalVisibilityAction,
  getReplaceItemIdSelector,
  getEquivalentItemsSelector,
  enableSectionReOrderSelector,
} from '../../../../ducks'
import { clearAnswersAction } from '../../../../../src/actions/answers'
import { clearEvaluationAction } from '../../../../../../assessment/actions/evaluation'
import { getSummarySelector } from '../../../Summary/ducks'
import { getQuestionsSelectorForReview } from '../../../../../sharedDucks/questions'
import Breadcrumb from '../../../../../src/components/Breadcrumb'
import ReviewSummary from '../ReviewSummary/ReviewSummary'
import {
  SecondHeader,
  ReviewSummaryWrapper,
  ReviewContentWrapper,
  ReviewLeftContainer,
  TestTitle,
  SecondHeaderWrapper,
} from './styled'
import { clearDictAlignmentAction } from '../../../../../src/actions/dictionaries'
import { getCreateItemModalVisibleSelector } from '../../../../../src/selectors/testItem'
import {
  getUserFeatures,
  getUserRole,
  getIsPowerPremiumAccount,
  getCollectionsSelector,
} from '../../../../../src/selectors/user'
import TestPreviewModal from '../../../../../Assignments/components/Container/TestPreviewModal'
import ReviewItems from '../ReviewItems'
import { resetItemScoreAction } from '../../../../../src/ItemScore/ducks'
import {
  groupTestItemsByPassageId,
  reOrderItemToNewIndex,
  getItemsGroupedByPassageId,
  getShowPDLMissingStandardsBanner,
} from '../helper'
import { getIsPreviewModalVisibleSelector } from '../../../../../../assessment/selectors/test'
import { setIsTestPreviewVisibleAction } from '../../../../../../assessment/actions/test'
import { STATUS } from '../../../../../AssessmentCreate/components/CreateAITest/ducks/constants'
import AddMoreQuestionsPannel from '../AddMoreQuestionsPannel/AddMoreQuestionsPannel'
import AutoSelectScoreChangeModal from '../AutoSelectScoreChangeModal/AutoSelectScoreChangeModel'
import AIEnhanceQuestion from '../../../../../AIFeatures/containers/AIEnhanceQuestion'
import PDLMissingStandardsBanner from '../PDLMissingStandardsBanner.js'
import ClarifierProvider from '../../../../../../assessment/components/Clarifiers/components/ClarifierProvider'

class Review extends PureComponent {
  secondHeaderRef = React.createRef()

  containerRef = React.createRef()

  listWrapperRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      isCollapse: true,
      isShowSummary: true,
      item: [],
      currentTestId: '',
      hasStickyHeader: false,
      indexForPreview: 0,
      groupIndex: 0,
      showAutoSelectScoreChangeModal: false,
      currentGroupId: '',
      refreshing: false,
      currentReplaceItemIndex: 0,
      dragState: {
        itemId: null,
        groupId: null,
      },
      aiQuestionReviseDrawer: {
        isOpen: false,
        testItem: null,
        defaultSelectedTab: null,
      },
    }
  }

  componentWillUnmount() {
    if (this.containerRef.current) {
      this.containerRef.current.removeEventListener('scroll', this.handleScroll)
    }
  }

  componentDidMount() {
    this.containerRef?.current?.addEventListener('scroll', this.handleScroll)
    // url = http://localhost:3001/author/tests/tab/review/id/testId/
    // ?token=value&firebaseToken=value&userId=value&role=teacher&itemBank=cli&showCLIBanner=1
    // &showAssingmentPreview=1
    const { showAssignmentPreview } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    if (showAssignmentPreview) {
      const { setIsTestPreviewVisible } = this.props
      setIsTestPreviewVisible(true)
    }
    const { getAllTags, isPlaylistTestReview = false } = this.props
    getAllTags({
      type: isPlaylistTestReview ? 'playlist' : ['test', 'assignment'],
    })
  }

  onSetDragState = ({ itemId, groupId }) => {
    this.setState({
      dragState: {
        itemId,
        groupId,
      },
    })
  }

  onDrop = (targetGroupId) => {
    const { test, setData } = this.props
    const { groupId: sourceGroupId, itemId } = this.state.dragState

    if (!targetGroupId || targetGroupId === sourceGroupId) {
      return
    }
    const sourceGroup = test.itemGroups.find(
      (group) => group._id === sourceGroupId
    )
    const testItem = sourceGroup.items.find((item) => item._id === itemId)
    const newItemGroups = produce(test.itemGroups, (draft) => {
      draft.forEach((group) => {
        if (group._id === sourceGroupId) {
          group.items = group.items.filter((item) => {
            if (testItem.passageId) {
              return item.passageId !== testItem.passageId
            }
            return item._id !== itemId
          })
        }

        if (group._id === targetGroupId) {
          const itemsToMove = testItem.passageId
            ? sourceGroup.items.filter(
                (item) => item.passageId === testItem.passageId
              )
            : [testItem]
          group.items.push(...itemsToMove)
        }
      })
    })
    setData({ itemGroups: newItemGroups })
  }

  setSelected = (values) => {
    const { test, setData } = this.props
    const newData = cloneDeep(test)
    newData.itemGroups = produce(newData.itemGroups, (itemGroups) => {
      itemGroups
        .flatMap((itemGroup) => itemGroup.items || [])
        .map((item) => {
          if (values.includes(item._id)) {
            item.selected = true
          } else {
            item.selected = false
          }
          return null
        })
    })
    setData(newData)
  }

  handleSelectAll = (e) => {
    const { test } = this.props
    const { checked } = e.target
    if (checked) {
      this.setSelected(
        test.itemGroups
          .flatMap((itemGroup) => itemGroup.items || [])
          .map((item) => item._id)
      )
    } else {
      this.setSelected([])
    }
  }

  handleRemoveSelected = () => {
    const { test, setData, setTestItems } = this.props
    const newData = cloneDeep(test)
    const itemsSelected = test.itemGroups
      .flatMap((itemGroup) => itemGroup.items || [])
      .filter((item) => item.selected)
      .map((item) => item._id)
    if (!itemsSelected.length) {
      return notification({
        type: 'warn',
        messageKey: 'pleaseSelectAtleastOneQuestion',
      })
    }
    newData.itemGroups = newData.itemGroups.map((itemGroup) => ({
      ...itemGroup,
      items: itemGroup.items.filter(
        (testItem) => !itemsSelected.includes(testItem._id)
      ),
    }))

    newData.scoring.testItems = newData.scoring.testItems.filter((item) => {
      const foundItem = newData.itemGroups
        .flatMap((itemGroup) => itemGroup.items || [])
        .find(({ id }) => id === item._id)
      return !(foundItem && foundItem.selected)
    })
    const testItems = newData.itemGroups.flatMap(
      (itemGroup) => itemGroup.items || []
    )

    setTestItems(testItems.map((item) => item._id))
    this.setSelected([])
    setData(newData)
    notification({
      type: 'success',
      messageKey: 'selectedItemRemovedSuccessfully',
    })
    if (test.aiDocExtracted || test.aiGenerated) {
      segmentApi.genericEventTrack('TestReview:BulkDeleteItems', {
        aiDocExtracted: test.aiDocExtracted,
        aiGenerated: test.aiGenerated,
        count: itemsSelected.length,
      })
    }
  }

  handleRemoveOne = (itemId) => {
    const { test, setData, setTestItems } = this.props
    const newData = cloneDeep(test)

    let itemsSelected
    let itemsSelectedIndex
    const itemGroupIndex = newData.itemGroups.findIndex((itemGroup) => {
      itemsSelectedIndex = itemGroup.items.findIndex(({ _id }) => _id == itemId)
      if (itemsSelectedIndex >= 0) {
        itemsSelected = itemGroup.items[itemsSelectedIndex]
        return true
      }
      return false
    })

    if (!itemsSelected) {
      return notification({
        type: 'warn',
        messageKey: 'pleaseSelectAtleastOneQuestion',
      })
    }

    if (
      newData.itemGroups[itemGroupIndex].type === ITEM_GROUP_TYPES.AUTOSELECT
    ) {
      this.refreshGroupItems(itemGroupIndex, itemsSelectedIndex)
      return
    }

    newData.itemGroups = newData.itemGroups.map((itemGroup) => ({
      ...itemGroup,
      items: itemGroup.items.filter(
        (testItem) => testItem._id !== itemsSelected._id
      ),
    }))

    newData.scoring.testItems = newData.scoring.testItems.filter((item) =>
      newData.itemGroups
        .flatMap((itemGroup) => itemGroup.items || [])
        .find(({ id }) => id === item._id && itemsSelected._id !== id)
    )

    const testItems = newData.itemGroups.flatMap(
      (itemGroup) => itemGroup.items || []
    )

    setTestItems(testItems.map((item) => item._id))
    setData(newData)

    if (test.aiDocExtracted || test.aiGenerated) {
      segmentApi.genericEventTrack('TestReview:DeleteItem', {
        aiDocExtracted: test.aiDocExtracted,
        aiGenerated: test.aiGenerated,
      })
    }
  }

  handleRemoveMultiple = (itemIds) => {
    if (isEmpty(itemIds)) {
      return notification({
        type: 'warn',
        messageKey: 'pleaseSelectAtleastOneQuestion',
      })
    }
    const { test, setData, setTestItems } = this.props
    const newData = cloneDeep(test)

    newData.itemGroups = newData.itemGroups.map((itemGroup) => ({
      ...itemGroup,
      items: itemGroup.items.filter(
        (testItem) => !itemIds.includes(testItem._id)
      ),
    }))

    newData.scoring.testItems = newData.scoring.testItems.filter((item) => {
      const foundItem = newData.itemGroups
        .flatMap((itemGroup) => itemGroup.items || [])
        .find(({ id }) => id === item._id)

      return !(foundItem && itemIds.includes(foundItem._id))
    })
    const testItems = newData.itemGroups.flatMap(
      (itemGroup) => itemGroup.items || []
    )

    setTestItems(testItems.map((item) => item._id))
    setData(newData)
    notification({
      type: 'success',
      msg: `${itemIds.length} item(s) removed successfully`,
    })
  }

  handleCollapse = () => {
    const { isCollapse } = this.state
    this.setState({ isCollapse: !isCollapse })
  }

  toggleSummary = () => {
    const { isShowSummary } = this.state
    this.setState({
      isShowSummary: !isShowSummary,
    })
  }

  moveTestItems = ({ oldIndex, newIndex }) => {
    const { test, setData } = this.props
    if (test.itemGroups.length > 1) {
      let itemCounter = 0
      let [newGroupIndex, newItemIndex] = [0, 0]
      let [oldGroupIndex, oldItemIndex] = [0, 0]
      test.itemGroups.forEach((group, i) => {
        group.items.forEach((_, j) => {
          if (itemCounter === newIndex) {
            ;[newGroupIndex, newItemIndex] = [i, j]
          }
          if (itemCounter === oldIndex) {
            ;[oldGroupIndex, oldItemIndex] = [i, j]
          }
          itemCounter++
        })
      })
      setData(
        produce(test, (draft) => {
          const tempItem = test.itemGroups[oldGroupIndex].items[oldItemIndex]
          delete tempItem.selected
          draft.itemGroups[oldGroupIndex].items[oldItemIndex] =
            draft.itemGroups[newGroupIndex].items[newItemIndex]
          draft.itemGroups[newGroupIndex].items[newItemIndex] = tempItem
        })
      )
    } else {
      setData(
        produce(test, (draft) => {
          draft.itemGroups[0].items = draft.itemGroups[0].items.map(
            ({ selected, ...item }) => ({
              ...item,
            })
          )
          const [removed] = draft.itemGroups[0].items.splice(oldIndex, 1)
          draft.itemGroups[0].items.splice(newIndex, 0, removed)
        })
      )
    }
  }

  completeMoveTestItems = (items) => {
    const { test, setData } = this.props
    if (test.itemGroups.length > 1) {
      const itemsGroupedByGroupId = groupBy(items, 'groupId')
      const testdata = produce(test, (draft) => {
        draft.itemGroups.forEach((itemGroup) => {
          itemGroup.items = itemsGroupedByGroupId[itemGroup._id] || []
        })
      })
      setData(testdata)
    } else {
      setData(
        produce(test, (draft) => {
          draft.itemGroups[0].items = items
        })
      )
    }
  }

  handleMoveTo = (newIndex) => {
    const { test, setData, enableSectionReOrder } = this.props
    const itemsGroupedByPassageId = getItemsGroupedByPassageId(test.itemGroups)
    const selectedIndex = itemsGroupedByPassageId.findIndex(
      (item) => item.selected
    )
    // Nothing selected or old and new index are same then don't proceed
    if (!selectedIndex === -1 || selectedIndex === newIndex) {
      return
    }
    const [selectedItem, targetLocationItem] = [
      itemsGroupedByPassageId[selectedIndex],
      itemsGroupedByPassageId[newIndex],
    ]
    // do not perform cross section re order incase both items are from same group
    // continue to old logic
    if (
      enableSectionReOrder &&
      selectedItem.groupId !== targetLocationItem.groupId
    ) {
      setData({
        itemGroups: reOrderItemToNewIndex(
          newIndex,
          selectedIndex,
          test.itemGroups,
          itemsGroupedByPassageId
        ),
      })
      return
    }
    setData(
      produce(test, (draft) => {
        draft.itemGroups = draft.itemGroups.map((itemGroup) => {
          const groupedItems = groupTestItemsByPassageId(itemGroup.items)
          const selectedItemIndex = groupedItems.findIndex((item) => {
            if (isArray(item)) {
              return item.some((ite) => ite.selected)
            }
            return item.selected
          })
          if (selectedItemIndex > -1) {
            const totalItemsInAboveGrp = draft.itemGroups.reduce(
              (acc, curr) => {
                if (curr.index < itemGroup.index) {
                  return acc + curr.items.length
                }
                return acc + 0
              },
              0
            )
            const [removed] = groupedItems.splice(selectedItemIndex, 1)
            groupedItems.splice(newIndex - totalItemsInAboveGrp, 0, removed)
            itemGroup.items = flatten(groupedItems)
          }
          return itemGroup
        })
      })
    )
  }

  handleBlur = (testItemId) => {
    const { test } = this.props

    let item = null
    test?.itemGroups?.forEach((itemGroup) => {
      if (!item) {
        item = itemGroup.items?.find(({ _id }) => _id === testItemId)
      }
    })
    if (
      item?.data?.questions.some(
        (question) => question?.validation?.altResponses?.length
      )
    ) {
      notification({
        type: 'info',
        messageKey: 'altResponseAvailableInItem',
      })
    }
  }

  handleChangePoints = (testItemId, itemScore, questionLevelScore) => {
    /**
     * prevent zero or less
     */
    if (!(itemScore > 0)) {
      return
    }
    const { test, setData } = this.props
    const newData = cloneDeep(test)

    if (!newData.scoring) newData.scoring = {}
    newData.scoring[testItemId] = itemScore
    if (questionLevelScore) {
      set(newData, `scoring.questionLevel.${testItemId}`, questionLevelScore)
    }

    setData(newData)
  }

  handleDuplicateItem = (duplicateTestItemId) => {
    const {
      onSaveTestId,
      test: { title, _id: testId },
      clearDictAlignment,
      history,
    } = this.props
    if (!title) {
      notification({ messageKey: 'nameShouldNotEmpty' })
    }
    clearDictAlignment()
    onSaveTestId()
    history.push(`/author/tests/${testId}/createItem/${duplicateTestItemId}`)
  }

  getGroupIndex = (itemId) => {
    const { testGroups } = this.props

    const groupIndex = testGroups?.findIndex((group) =>
      group.items?.some((item) => item._id === itemId)
    )
    return groupIndex
  }

  handlePreviewTestItem = (data) => {
    const { resetItemScore, testItems, setModalVisibility } = this.props
    const indexForPreview = findIndex(testItems, (ite) => ite._id === data)
    const groupIndex = this.getGroupIndex(data)
    this.setState({
      item: { id: data },
      indexForPreview,
      groupIndex,
    })
    // clear the item score in the store, if while adding item, check/show answer was clicked
    // EV-12256
    resetItemScore()
    setModalVisibility(true)
  }

  handleAiOptionSelected = (option, question) => {
    const testItem = this.props.testItems?.find(
      (ite) => ite._id === (question.id || question._id)
    )
    this.setState({
      aiQuestionReviseDrawer: {
        isOpen: true,
        testItem,
        defaultSelectedTab: option,
      },
    })
  }

  nextItem = () => {
    const { indexForPreview } = this.state
    const { testItems, resetItemScore } = this.props
    const nextItemIndex = indexForPreview + 1
    if (nextItemIndex > testItems.length - 1) {
      return
    }
    resetItemScore()
    const itemId = testItems[nextItemIndex]._id
    const groupIndex = this.getGroupIndex(itemId)

    this.setState({
      item: { id: itemId },
      indexForPreview: nextItemIndex,
      groupIndex,
    })
  }

  prevItem = () => {
    const { indexForPreview } = this.state
    const { testItems, resetItemScore } = this.props
    const prevItemIndex = indexForPreview - 1
    if (prevItemIndex < 0) {
      return
    }
    resetItemScore()
    const itemId = testItems?.[prevItemIndex]?._id
    const groupIndex = this.getGroupIndex(itemId)
    this.setState({
      item: { id: testItems?.[prevItemIndex]?._id },
      indexForPreview: prevItemIndex,
      groupIndex,
    })
  }

  setNextPreviewItem = (removedItemId) => {
    const { testItems } = this.props
    const newItems = testItems.filter((item) => item._id !== removedItemId)
    if (newItems.length === 0) {
      return
    }
    let nextPreviewItemIndex = testItems.findIndex(
      (item) => item._id === removedItemId
    )
    if (last(testItems)._id === removedItemId) {
      // last item removed then preview second last item. Otherwise preview item with similar index
      nextPreviewItemIndex -= 1
    }
    const nextItemId = newItems[nextPreviewItemIndex]._id
    const groupIndex = this.getGroupIndex(nextItemId)
    this.setState({
      item: { id: nextItemId },
      indexForPreview: nextPreviewItemIndex,
      groupIndex,
    })
  }

  closeModal = () => {
    const { clearEvaluation, setModalVisibility } = this.props
    setModalVisibility(false)
    clearEvaluation()
  }

  // changing this
  handleChangeField = (field, value) => {
    const { setData, updateDefaultThumbnail } = this.props
    if (field === 'thumbnail') {
      updateDefaultThumbnail('')
    }
    setData({ [field]: value })
  }

  hidePreviewModal = () => {
    const { clearAnswer, clearEvaluation, setIsTestPreviewVisible } = this.props
    setIsTestPreviewVisible(false)
    clearAnswer()
    clearEvaluation()
  }

  showTestPreviewModal = () => {
    const { test, setIsTestPreviewVisible } = this.props
    setIsTestPreviewVisible(true)
    this.setState({ currentTestId: test._id })
  }

  handleScroll = (e) => {
    const element = e.target
    const { hasStickyHeader } = this.state
    if (this.secondHeaderRef.current) {
      if (element.scrollTop > 50 && !hasStickyHeader) {
        this.secondHeaderRef.current.classList.add('fixed-second-header')
        this.setState({ hasStickyHeader: true })
      } else if (element.scrollTop <= 50 && hasStickyHeader) {
        this.secondHeaderRef.current.classList.remove('fixed-second-header')
        this.setState({ hasStickyHeader: false })
      }
    }
  }

  closeTestPreviewModal = () => {
    const { setIsTestPreviewVisible } = this.props
    setIsTestPreviewVisible(false)
  }

  refreshGroupItems = async (groupSelectedIndex, itemsSelectedIndex = -1) => {
    const { test, handleSave, setData } = this.props
    const { refreshing } = this.state
    const { itemGroups } = test
    const currentGroup = itemGroups[groupSelectedIndex]
    if (refreshing) {
      return
    }
    this.setState({ refreshing: true })
    const optionalFields = {
      depthOfKnowledge: currentGroup.dok,
      authorDifficulty: currentGroup.difficulty,
      tags: currentGroup.tags?.map((tag) => tag.tagName) || [],
    }
    Object.keys(optionalFields).forEach(
      (key) => optionalFields[key] === undefined && delete optionalFields[key]
    )

    const data = {
      limit:
        itemsSelectedIndex >= 0
          ? 1
          : currentGroup.deliveryType ===
            ITEM_GROUP_DELIVERY_TYPES.LIMITED_RANDOM
          ? currentGroup.pickCount || currentGroup.items.length || 2
          : currentGroup.deliverItemsCount,
      search: {
        collectionId: currentGroup.collectionDetails._id,
        standardIds: currentGroup.standardDetails.standards.map(
          (std) => std.standardId
        ),
        ...optionalFields,
      },
    }
    if (data.limit > MAX_ITEMS_DELIVER_COUNT) {
      notification({ messageKey: 'maximum100Questions' })
      return
    }

    await testItemsApi
      .getAutoSelectedItems({
        ...data,
        search: {
          ...data.search,
          nInItemIds: itemGroups
            .flatMap((_itemGroup) => _itemGroup.items || [])
            .map(({ _id }) => _id),
        },
      })
      .then((res) => {
        const { items, total } = res
        if (items.length === 0) {
          notification({ messageKey: 'noItemsFoundForCurrentCombination' })
          return
        }
        if (total < data.limit) {
          return notification({
            msg: `There are only ${total} items that meet the search criteria`,
          })
        }
        const updatedItemGroups = produce(itemGroups, (draft) => {
          if (itemsSelectedIndex >= 0) {
            draft[groupSelectedIndex].items = draft[
              groupSelectedIndex
            ].items.map((item, itemIndex) =>
              itemIndex === itemsSelectedIndex ? items[0] : item
            )
          } else {
            draft[groupSelectedIndex].items = items
          }
        })
        setData({
          itemGroups: updatedItemGroups,
        })
        handleSave()
        this.setState({ refreshing: false })
        notification({
          msg:
            itemsSelectedIndex > -1
              ? `New item is added to ${currentGroup.groupName}`
              : `New items are added to ${currentGroup.groupName}`,
        })
      })
      .catch((err) => {
        this.setState({ refreshing: false })
        notification({ msg: err.message || 'Failed to fetch test items' })
      })
  }

  setShowAutoSelectScoreChangeModal = (groupId) => {
    this.setState({
      showAutoSelectScoreChangeModal: true,
      currentGroupId: groupId,
    })
  }

  closeScoreChangeModal = () => {
    this.setState({
      showAutoSelectScoreChangeModal: false,
      currentGroupId: '',
    })
  }

  handleReplace = (itemId) => {
    const { replaceRestrictedItem, handleSave, test } = this.props
    if (test.status === statusConstants.PUBLISHED) {
      handleSave()
    }
    replaceRestrictedItem(itemId)
  }

  handlePaginationChange = (page) => {
    this.setState({
      currentReplaceItemIndex: page - 1,
    })
  }

  render() {
    const {
      test,
      current,
      windowWidth,
      rows,
      standards,
      onChangeGrade,
      onChangeSubjects,
      onChangeSkillIdentifiers,
      onChangeCollection,
      questions,
      owner,
      defaultThumbnail,
      isEditable = false,
      isTestEditable,
      isTestClonable,
      itemsSubjectAndGrade,
      checkAnswer,
      showAnswer,
      showCancelButton,
      userFeatures,
      testItems,
      isPlaylistTestReview = false,
      isFetchingAutoselectItems = false,
      userRole,
      isPowerPremiumAccount,
      showGroupsPanel,
      isPreviewModalVisible,
      playlistId,
      isTestsUpdated,
      orgCollections,
      userId,
      aiTestStatus,
      setData,
      handleNavChange,
      handleNavChangeToAddItems,
      handleSave,
      setSectionsState,
      setCurrentGroupDetails,
      hasSections,
      isDefaultTest,
      setSectionsTestSetGroupIndex,
      setShowSectionsTestSelectGroupIndexModal,
      onSaveTestId,
      updated,
      showSelectGroupIndexModal,
      selectedRows,
      adaptiveDelivery,
      isEditDisabledForSectionsAdaptiveTest,
      t: i18translate,
      isItemPreviewModalVisible,
      replaceItemId,
      equivalentItems,
      adaptiveTestSettingLabel,
      gotoManageSections,
    } = this.props
    const {
      isCollapse,
      isShowSummary,
      item: previewItem,
      currentTestId,
      hasStickyHeader,
      groupIndex,
      showAutoSelectScoreChangeModal,
      currentGroupId,
      currentReplaceItemIndex,
      dragState,
      aiQuestionReviseDrawer,
    } = this.state
    const item =
      replaceItemId && equivalentItems.length > 0
        ? { id: equivalentItems[currentReplaceItemIndex]._id }
        : previewItem
    // when redirected from other pages, sometimes, test will only be having
    // ids in its testitems, which could create issues.
    if (
      test?.itemGroups?.[0]?.items &&
      test?.itemGroups?.[0]?.items?.some((i) => typeof i === 'string')
    ) {
      return <div />
    }

    const isTestAuthor = test?.authors?.some((author) => author._id === userId)

    const selected = test?.itemGroups
      ?.flatMap((itemGroup) => itemGroup?.items || [])
      .reduce((acc, element) => {
        if (element.selected) {
          acc.push(element._id)
        }
        return acc
      }, [])
    const breadcrumbData = [
      {
        title: showCancelButton ? 'ASSIGNMENTS / EDIT TEST' : 'TESTS',
        to: showCancelButton ? '/author/assignments' : '/author/tests',
      },
      {
        title: current,
        to: '',
      },
      {
        title: test.title,
        to: '',
      },
    ]

    const isSmallSize = windowWidth > 993 ? 1 : 0
    const grades = _uniq([
      ...(test.grades || []),
      ...itemsSubjectAndGrade.grades,
    ]).filter((grade) => !isEmpty(grade))
    const subjects = _uniq([
      ...(test.subjects || []),
      ...itemsSubjectAndGrade.subjects,
    ]).filter((grade) => !isEmpty(grade))
    const collections = get(test, 'collections', [])
    const passages = get(test, 'passages', [])
    const passagesKeyed = keyBy(passages, '_id')
    const isPublishers =
      userFeatures.isPublisherAuthor || userFeatures.isCurator
    const groupedItems = groupTestItemsByPassageId(testItems)
    const showPDLMissingStandardsBanner = getShowPDLMissingStandardsBanner(
      test,
      testItems
    )

    return (
      <MainContentWrapper ref={this.containerRef}>
        {isPlaylistTestReview ? (
          <Row>
            <Col lg={24}>
              <SecondHeader>
                <TestTitle data-cy="testTitle">{test?.title}</TestTitle>
              </SecondHeader>
            </Col>
          </Row>
        ) : (
          <SecondHeaderWrapper>
            <Col lg={24} xl={owner && isEditable ? 24 : 18}>
              <div ref={this.secondHeaderRef}>
                <SecondHeader>
                  <Breadcrumb
                    data={breadcrumbData}
                    style={{ position: 'unset' }}
                  />
                  <HeaderBar
                    onSelectAll={this.handleSelectAll}
                    itemTotal={groupedItems.length}
                    selectedItems={selected}
                    onRemoveSelected={this.handleRemoveSelected}
                    onCollapse={this.handleCollapse}
                    onMoveTo={this.handleMoveTo}
                    owner={owner}
                    isEditable={isEditable}
                    windowWidth={windowWidth}
                    setCollapse={isCollapse}
                    toggleSummary={this.toggleSummary}
                    isShowSummary={isShowSummary}
                    onShowTestPreview={this.showTestPreviewModal}
                    hasStickyHeader={hasStickyHeader}
                    itemGroups={test.itemGroups}
                    hasSections={hasSections}
                    isDefaultTest={isDefaultTest}
                    setData={setData}
                    handleNavChange={handleNavChange}
                    handleSave={handleSave}
                    setSectionsState={setSectionsState}
                    testId={
                      test?._id || get(this.props, 'match.params.id', false)
                    }
                    setCurrentGroupDetails={setCurrentGroupDetails}
                    i18translate={i18translate}
                    isEditDisabledForSectionsAdaptiveTest={
                      isEditDisabledForSectionsAdaptiveTest
                    }
                  />
                </SecondHeader>
              </div>
            </Col>
          </SecondHeaderWrapper>
        )}
        <ReviewContentWrapper>
          <ReviewLeftContainer lg={24} xl={18}>
            <Paper
              padding="7px 0px"
              style={{ overflow: 'hidden' }}
              ref={this.listWrapperRef}
            >
              {showPDLMissingStandardsBanner && <PDLMissingStandardsBanner />}
              <ReviewItems
                items={groupedItems}
                scoring={test.scoring}
                standards={standards}
                userFeatures={userFeatures}
                isEditable={isEditable}
                isCollapse={isCollapse}
                passagesKeyed={passagesKeyed}
                rows={rows}
                isSmallSize={isSmallSize}
                onChangePoints={this.handleChangePoints}
                blur={this.handleBlur}
                handlePreview={this.handlePreviewTestItem}
                handleAiOptionSelected={this.handleAiOptionSelected}
                moveTestItems={this.moveTestItems}
                onCompleteMoveItem={this.completeMoveTestItems}
                removeSingle={this.handleRemoveOne}
                removeMultiple={this.handleRemoveMultiple}
                getContainer={() => this.containerRef.current}
                setSelected={this.setSelected}
                selected={selected}
                questions={questions}
                owner={owner}
                itemGroups={test.itemGroups}
                refreshGroupItems={this.refreshGroupItems}
                isPublishers={isPublishers}
                isFetchingAutoselectItems={isFetchingAutoselectItems}
                userRole={userRole}
                isPowerPremiumAccount={isPowerPremiumAccount}
                showGroupsPanel={showGroupsPanel}
                isTestsUpdated={isTestsUpdated}
                orgCollections={orgCollections}
                userId={userId}
                hasSections={hasSections}
                setShowAutoSelectScoreChangeModal={
                  this.setShowAutoSelectScoreChangeModal
                }
                adaptiveDelivery={adaptiveDelivery}
                handleReplace={this.handleReplace}
                isTestAuthor={isTestAuthor}
                isEditDisabledForSectionsAdaptiveTest={
                  isEditDisabledForSectionsAdaptiveTest
                }
                onSetDragState={this.onSetDragState}
                onDrop={this.onDrop}
                dragState={dragState}
                adaptiveTestSettingLabel={adaptiveTestSettingLabel}
                gotoManageSections={gotoManageSections}
              />
              <AddMoreQuestionsPannel
                onSaveTestId={onSaveTestId}
                test={test}
                handleSave={handleSave}
                updated={updated}
                showSelectGroupIndexModal={showSelectGroupIndexModal}
                handleNavChangeToAddItems={handleNavChangeToAddItems}
                isEditable={isEditable}
                isTestEditable={isTestEditable}
                isTestClonable={isTestClonable}
                isEditDisabledForSectionsAdaptiveTest={
                  isEditDisabledForSectionsAdaptiveTest
                }
              />
            </Paper>
          </ReviewLeftContainer>
          {isShowSummary && (
            <ReviewSummaryWrapper lg={24} xl={6}>
              <ReviewSummary
                grades={grades}
                subjects={subjects}
                collections={collections}
                owner={owner}
                isEditable={isEditable}
                onChangeField={this.handleChangeField}
                thumbnail={defaultThumbnail || test.thumbnail}
                onChangeGrade={onChangeGrade}
                onChangeSubjects={onChangeSubjects}
                onChangeSkillIdentifiers={onChangeSkillIdentifiers}
                onChangeCollection={onChangeCollection}
                windowWidth={windowWidth}
                isPublishers={isPublishers}
                testCategory={test?.testCategory}
              />
            </ReviewSummaryWrapper>
          )}
          {aiQuestionReviseDrawer.isOpen && (
            <AIEnhanceQuestion
              onCancel={() => {
                this.setState({
                  aiQuestionReviseDrawer: {
                    isOpen: false,
                    testItem: null,
                  },
                })
              }}
              isVisible
              questionData={aiQuestionReviseDrawer.testItem}
              defaultSelectedTab={aiQuestionReviseDrawer.defaultSelectedTab}
            />
          )}
        </ReviewContentWrapper>
        {isItemPreviewModalVisible && (
          <Spin spinning={aiTestStatus === STATUS.INPROGRESS}>
            <ClarifierProvider>
              <PreviewModal
                testId={test?._id || get(this.props, 'match.params.id', false)}
                isTest={!!test}
                isVisible={isItemPreviewModalVisible}
                onClose={this.closeModal}
                showModal
                isEditable={
                  isEditable || userRole === roleuser.EDULASTIC_CURATOR
                }
                owner={owner}
                groupIndex={groupIndex}
                addDuplicate={this.handleDuplicateItem}
                page={replaceItemId ? 'replaceItem' : 'review'}
                testStatus={test.status}
                data={item}
                questions={questions}
                checkAnswer={() => checkAnswer(item)}
                showAnswer={() => showAnswer(item)}
                prevItem={this.prevItem}
                nextItem={this.nextItem}
                showEvaluationButtons
                isPlaylistTestReview={isPlaylistTestReview}
                playlistId={playlistId}
                hasSections={hasSections}
                setSectionsTestSetGroupIndex={setSectionsTestSetGroupIndex}
                setShowSectionsTestSelectGroupIndexModal={
                  setShowSectionsTestSelectGroupIndexModal
                }
                selectedRows={selectedRows}
                replaceItemId={replaceItemId}
                handlePaginationChange={this.handlePaginationChange}
                currentReplaceItemIndex={currentReplaceItemIndex}
                isEditDisabledForSectionsAdaptiveTest={
                  isEditDisabledForSectionsAdaptiveTest
                }
                handleAiOptionSelected={this.handleAiOptionSelected}
                setNextPreviewItem={this.setNextPreviewItem}
              />
            </ClarifierProvider>
          </Spin>
        )}
        <EduIf condition={showAutoSelectScoreChangeModal}>
          <AutoSelectScoreChangeModal
            visible={showAutoSelectScoreChangeModal}
            closeModal={this.closeScoreChangeModal}
            currentGroupId={currentGroupId}
            handleSave={handleSave}
          />
        </EduIf>

        <TestPreviewModal
          isModalVisible={isPreviewModalVisible}
          testId={currentTestId}
          test={test}
          showStudentPerformance
          closeTestPreviewModal={this.hidePreviewModal}
        />
      </MainContentWrapper>
    )
  }
}

Review.propTypes = {
  test: PropTypes.object.isRequired,
  onChangeGrade: PropTypes.func.isRequired,
  onChangeSubjects: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  clearEvaluation: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  standards: PropTypes.object.isRequired,
  current: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  clearDictAlignment: PropTypes.func.isRequired,
  owner: PropTypes.bool,
  onSaveTestId: PropTypes.func,
  history: PropTypes.any.isRequired,
  clearAnswer: PropTypes.func.isRequired,
  checkAnswer: PropTypes.func.isRequired,
  showAnswer: PropTypes.func.isRequired,
  updateDefaultThumbnail: PropTypes.func.isRequired,
  onChangeCollection: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
  itemsSubjectAndGrade: PropTypes.any.isRequired,
  isEditable: PropTypes.bool.isRequired,
  defaultThumbnail: PropTypes.any.isRequired,
  setTestItems: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool.isRequired,
  userFeatures: PropTypes.object.isRequired,
  testItems: PropTypes.array.isRequired,
}

Review.defaultProps = {
  owner: false,
  onSaveTestId: () => {},
}

const enhance = compose(
  withNamespaces('author'),
  withRouter,
  withWindowSizes,
  connect(
    (state) => ({
      standards: getStandardsSelector(state),
      summary: getSummarySelector(state),
      createTestItemModalVisible: getCreateItemModalVisibleSelector(state),
      questions: getQuestionsSelectorForReview(state),
      defaultThumbnail: getDefaultThumbnailSelector(state),
      itemsSubjectAndGrade: getItemsSubjectAndGradeSelector(state),
      userFeatures: getUserFeatures(state),
      testGroups: getItemGroupsSelector(state),
      testItems: getTestItemsSelector(state),
      isFetchingAutoselectItems: getAutoSelectItemsLoadingStatusSelector(state),
      userRole: getUserRole(state),
      isPowerPremiumAccount: getIsPowerPremiumAccount(state),
      showGroupsPanel: showGroupsPanelSelector(state),
      isPreviewModalVisible: getIsPreviewModalVisibleSelector(state),
      isTestsUpdated: getTestsUpdatedSelector(state),
      orgCollections: getCollectionsSelector(state),
      aiTestStatus: get(state, 'aiTestDetails.status'),
      hasSections: hasSectionsSelector(state),
      isDefaultTest: isDefaultTestSelector(state),
      selectedRows: getAuthoringTestItemsSelector(state),
      adaptiveDelivery: getAdaptiveDeliverySelector(state),
      isItemPreviewModalVisible: getIsItemPreviewModalVisibleSelector(state),
      replaceItemId: getReplaceItemIdSelector(state),
      equivalentItems: getEquivalentItemsSelector(state),
      enableSectionReOrder: enableSectionReOrderSelector(state),
      adaptiveTestSettingLabel: getAdaptiveSettingLabel(state),
    }),
    {
      setData: setTestDataAction,
      getAllTags: getAllTagsAction,
      updateDefaultThumbnail: updateDefaultThumbnailAction,
      clearDictAlignment: clearDictAlignmentAction,
      checkAnswer: previewCheckAnswerAction,
      showAnswer: previewShowAnswerAction,
      clearAnswer: clearAnswersAction,
      clearEvaluation: clearEvaluationAction,
      setTestItems: setTestItemsAction,
      resetItemScore: resetItemScoreAction,
      setIsTestPreviewVisible: setIsTestPreviewVisibleAction,
      setModalVisibility: setModalVisibilityAction,
      replaceRestrictedItem: replaceRestrictedItemAction,
    }
  )
)

export default enhance(Review)
