import React from 'react'
import { sanitizeHtml } from '@edulastic/common/src/utils/html'
import { IconPencilEdit, IconTrash } from '@edulastic/icons'
import { accessibilityColors, themeColor } from '@edulastic/colors'
import {
  StyledActionButtonContainer,
  StyledActionButtonFooterContainer,
  StyledClarifierContentStimulus,
  StyledModalDivider,
  StyledViewClarifierContainer,
  StyledViewClarifierSelectionStimulus,
} from '../styled'
import PopoverContainer from './PopoverContainer'

const ExplainerActions = ({ onClickRemove, onClickEdit }) => {
  return (
    <StyledActionButtonFooterContainer>
      <StyledActionButtonContainer
        data-cy="view-clarifier-delete-button"
        iconColor={accessibilityColors.theme.red}
        onClick={onClickRemove}
      >
        <IconTrash />
        Remove Explainer
      </StyledActionButtonContainer>
      <StyledActionButtonContainer
        data-cy="view-clarifier-edit-button"
        iconColor={themeColor}
        onClick={onClickEdit}
      >
        <IconPencilEdit />
        Edit Explainer
      </StyledActionButtonContainer>
    </StyledActionButtonFooterContainer>
  )
}

const ViewClarifierPopover = ({
  content,
  onClickRemove,
  onClickEdit,
  selectedTextTitle,
  isEditable,
  styles,
}) => {
  const Content = (
    <StyledViewClarifierContainer
      data-cy="view-clarifier-popover"
      onMouseDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div>
        <>
          <StyledViewClarifierSelectionStimulus
            data-cy="view-clarifier-selected-text-title"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(selectedTextTitle),
            }}
          />
        </>
        <StyledClarifierContentStimulus
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content),
          }}
        />
      </div>
      {isEditable && (
        <>
          <StyledModalDivider
            type="horizontal"
            style={{
              marginTop: '16px',
              marginBottom: '0px',
            }}
          />
          <ExplainerActions
            onClickRemove={onClickRemove}
            onClickEdit={onClickEdit}
          />
        </>
      )}
    </StyledViewClarifierContainer>
  )

  return <PopoverContainer styles={styles} content={Content} />
}

export default ViewClarifierPopover
