import React, { useState } from 'react'
import loadable from '@loadable/component'
import Progress from '@edulastic/common/src/components/Progress'
import { FlexContainer, EduButton } from '@edulastic/common'
import { sanitizeHtml } from '@edulastic/common/src/utils/html'
import {
  StyledAddClarifierContentModal,
  ClarifierModalTitle,
  StyledAddClarifierContentModalWordSelectedText,
  StyledAddClarifierContentModalWordSelectedTextContainer,
  StyledFroalaWrapper,
  StyledWorldLimitExceededText,
  StyledAddEditClarifierSelectionStimulus,
} from '../styled'
import { stripHtml } from '../../../../author/ClassBoard/Transformer'
import { FROALA_EDITOR_MAX_CHAR_LIMIT } from '../constants'

const FroalaEditor = loadable(() =>
  import(
    /* webpackChunkName: "froalaCommonChunk" */ '@edulastic/common/src/components/FroalaEditor'
  )
)

const AddEditClarifierContentModal = ({
  visible,
  onCloseModal,
  onSave,
  selectionStimulus,
  onEditorChange,
  content = '',
  isAdd,
}) => {
  const [charLimitExceeded, setCharLimitExceeded] = useState(false)

  const handleEditorChange = (htmlContent) => {
    const textContent = stripHtml(htmlContent)
    const length = textContent.length
    setCharLimitExceeded(length > FROALA_EDITOR_MAX_CHAR_LIMIT)

    onEditorChange(htmlContent)
  }

  return (
    <StyledAddClarifierContentModal
      data-cy={`${isAdd ? 'add' : 'edit'}-clarifier-modal`}
      title={
        <ClarifierModalTitle>
          {isAdd ? 'Add' : 'Edit'} Explainer
        </ClarifierModalTitle>
      }
      visible={visible}
      onCancel={onCloseModal}
      footer={
        <FlexContainer justifyContent="flex-end" mr="10px">
          <EduButton
            data-cy={`${isAdd ? 'add' : 'edit'}-clarifier-modal-save-button`}
            width="125px"
            height="32px"
            onClick={onSave}
            disabled={charLimitExceeded || !content.length}
          >
            SAVE
          </EduButton>
        </FlexContainer>
      }
      width="720px"
      height="540px"
      centered
    >
      <StyledAddClarifierContentModalWordSelectedTextContainer>
        <StyledAddClarifierContentModalWordSelectedText>
          Text Selected:{' '}
        </StyledAddClarifierContentModalWordSelectedText>
        <StyledAddEditClarifierSelectionStimulus
          data-cy="clarifier-selected-text"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(`“${selectionStimulus}”`),
          }}
        />
      </StyledAddClarifierContentModalWordSelectedTextContainer>
      <div>
        <StyledFroalaWrapper charLimitExceeded={charLimitExceeded}>
          <FroalaEditor
            value={content}
            fallback={<Progress />}
            onChange={handleEditorChange}
            toolbarInline
            toolbarVisibleWithoutSelection
            config={{
              placeholder: 'Enter Explainer',
            }}
            border="border"
            editorHeight={306}
            toolbarId="froala-editor-add-clarifier-content"
            autoFocus
          />
        </StyledFroalaWrapper>
        {charLimitExceeded && (
          <StyledWorldLimitExceededText>
            Word limit exceeded!
          </StyledWorldLimitExceededText>
        )}
      </div>
    </StyledAddClarifierContentModal>
  )
}

export default AddEditClarifierContentModal
