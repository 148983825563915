import { useCallback, useEffect, useState } from 'react'

const useTextSelect = ({ clarifierAllowedElements = [] }) => {
  const [selection, setSelection] = useState(null)
  const isTextSelectionAllowedElement = useCallback(
    (activeSelection) => {
      if (
        !activeSelection ||
        !activeSelection?.anchorNode ||
        !activeSelection?.focusNode ||
        !clarifierAllowedElements?.length
      ) {
        return false
      }

      return clarifierAllowedElements.some((element) => {
        return (
          element.contains(activeSelection.anchorNode) === true &&
          element.contains(activeSelection.focusNode) === true
        )
      })
    },
    [clarifierAllowedElements]
  )

  const onSelectStart = useCallback(() => {
    setSelection(undefined)
  }, [])

  const onSelectEnd = useCallback(() => {
    const activeSelection = document.getSelection()

    if (!activeSelection) {
      setSelection(null)
      return
    }

    if (isTextSelectionAllowedElement(activeSelection)) {
      setSelection(activeSelection)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('selectstart', onSelectStart)
    document.addEventListener('mouseup', onSelectEnd)
    return () => {
      document.removeEventListener('selectstart', onSelectStart)
      document.removeEventListener('mouseup', onSelectEnd)
    }
  }, [])

  return {
    selectedText: selection?.toString(),
    range: selection?.rangeCount > 0 ? selection.getRangeAt(0) : null,
    selection,
    setSelection,
  }
}

export default useTextSelect
