import React from 'react'
import { EduButton, FlexContainer } from '@edulastic/common'
import {
  ClarifierModalTitle,
  DeleteClarifierModalBodyText,
  StyledDeleteClarifierConfirmationButton,
  StyledDeleteClarifierModal,
} from '../styled'

const DeleteClarifierContentModal = ({ onCancel, onDelete }) => {
  return (
    <StyledDeleteClarifierModal
      data-cy="delete-clarifier-confirmation-modal"
      title={<ClarifierModalTitle>Remove Explainer</ClarifierModalTitle>}
      visible
      onCancel={onCancel}
      footer={
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          mr="10px"
          marginBottom="10px"
        >
          <EduButton
            data-cy="delete-clarifier-cancel"
            width="100px"
            height="32px"
            onClick={onCancel}
            isGhost
          >
            CANCEL
          </EduButton>
          <StyledDeleteClarifierConfirmationButton
            data-cy="delete-clarifier-confirm"
            width="100px"
            height="32px"
            onClick={onDelete}
          >
            REMOVE
          </StyledDeleteClarifierConfirmationButton>
        </FlexContainer>
      }
      width="400px"
      height="200px"
      centered
    >
      <DeleteClarifierModalBodyText>
        Are you sure to remove the explainer ?
      </DeleteClarifierModalBodyText>
    </StyledDeleteClarifierModal>
  )
}

export default DeleteClarifierContentModal
