import { smallDesktopWidth } from '@edulastic/colors'
import {
  COMPACT,
  EduIf,
  FieldLabel,
  FlexContainer,
  ItemDetailContext,
  PremiumItemBanner,
  withWindowSizes,
} from '@edulastic/common'
import { questionType, roleuser, test } from '@edulastic/constants'
import { AI_EVALUATION_STATUS } from '@edulastic/constants/const/evaluationType'
import { withNamespaces } from '@edulastic/localization'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import { cloneDeep, get, isEmpty, isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ThemeProvider, withTheme } from 'styled-components'
import { LANGUAGE_EN } from '@edulastic/constants/const/languages'
import { playerSkinValues } from '@edulastic/constants/const/test'
import { themes } from '../../theme'
import { questionTypeToComponent } from '../utils/questionTypeComponent'
import QuestionMenu, { AdvancedOptionsLink } from './QuestionMenu'

import { requestScratchPadAction } from '../../author/ExpressGrader/ducks'
import {
  getUserFeatures,
  getUserRole,
  isGcpsDistrictSelector,
} from '../../author/src/selectors/user'
import AudioControls from '../AudioControls'
import { setPassageCurrentPageAction } from '../actions/userInteractions'
import withAnswerSave from './HOC/withAnswerSave'

import PreviewRubricTable from '../../author/GradingRubric/Components/common/PreviewRubricTable'

import AIButtons from '../../author/AIFeatures/common/AIButtons'
import {
  ITEM_AUTHORING_PROMPTS,
  ITEM_TYPE_AND_PROMPT_MAP,
} from '../../author/AIFeatures/constants/prompt'
import AIItemAuthoringContainer from '../../author/AIFeatures/containers/AIItemAuthoring'
import {
  getIsVideoQuizSelector,
  isItemVisibiltySelector,
  ttsUserIdSelector,
} from '../../author/ClassBoard/ducks'
import ItemInvisible from '../../author/ExpressGrader/components/Question/ItemInvisible'
import { changedPassageContentAction } from '../../author/sharedDucks/testPlayer'
import {
  getCurrentLanguage,
  languagePreferenceSelector,
} from '../../common/components/LanguageSelectorTab/duck'
import { canUseAllOptionsByDefault } from '../../common/utils/helpers'
import {
  getAccommodationsTtsSelector,
  getUserAccommodations,
} from '../../student/Login/ducks'
import {
  EDIT,
  PREVIEW,
  stackedTranslationEnabledQuestionTypes,
  sttEnabledQuestionTypes,
} from '../constants/constantsForQuestions'
import { assignmentLevelSettingsSelector } from '../selectors/answers'
import {
  currentItemIndexSelector,
  getIsPreviewModalVisibleSelector,
  playerSkinTypeSelector,
} from '../selectors/test'
import {
  AiEvaluationMessage,
  AiEvaluationWrapper,
  EvaluationMessage,
  ManualEvaluationMessage,
  PaperWrapper,
  QuestionContainer,
  QuestionMenuWrapper,
  RubricTableWrapper,
  StyledFlexContainer,
} from '../styled/QuestionWrapperStyledComponents'
import {
  getFontSize,
  isSpeechToTextEnabled,
  isTextToSpeechEnabled,
} from '../utils/helpers'
import {
  changeDataToPreferredLanguage,
  getPrimaryLanguageCode,
} from '../utils/question'
import BottomAction from './Common/QuestionBottomAction'
import Hints from './Hints'
import ImmersiveReaderWrapper from './ImmersiveReadeWrapper'
import AIFeaturesSubscription from '../../author/AIFeatures/common/AIFeaturesSubscription'
import { AudioControlProvider } from './Common/StackedTranslator/AudioControlProvider'

import { isStackedTranslationEnabledSelector } from '../../common/components/LanguageSelector/duck'
import withStackedTranslator from './Common/StackedTranslator/withStackedTranslatorContext'
import {
  addItemClarifierAction,
  deleteItemClarifierAction,
  updateItemClarifierAction,
} from '../../author/ItemDetail/ducks'
import ClarifierAccessProvider from './Clarifiers/components/ClarifierAccessProvider'
import { getWrapperClarifierSource } from './Clarifiers/utils'
import { MAX_ALLOWED_CLARIFIER_PER_QUESTION } from './Clarifiers/constants'

const getQuestion = (type) =>
  questionTypeToComponent[type] || questionTypeToComponent.default

const { TEACHER, SCHOOL_ADMIN, DISTRICT_ADMIN } = roleuser

class QuestionWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      main: [],
      advanced: [],
      extras: [],
      activeTab: 0,
      shuffledOptsOrder: [],
      openedPrompt: false,
    }
  }

  /**
   * @see https://snapwiz.atlassian.net/browse/EV-34955
   * page data is required in ItemAudioControl component. Thus storing the page data in redux store
   */
  setPage = (page) => {
    const {
      setPassageCurrentPage,
      data: { id, type } = {},
      changedPassageContent,
    } = this.props
    if (id && type === questionType.PASSAGE) {
      setPassageCurrentPage({ passageId: id, page })
      /** Triggers action on passage page change */
      changedPassageContent()
    }
  }

  handleShuffledOptions = (shuffledOptsOrder) => {
    this.setState({ shuffledOptsOrder })
  }

  fillSections = (section, label, el, sectionId) => {
    if (typeof el !== 'object') return
    this.setState((state) => {
      const sectionState = state[section]
      const found = sectionState.filter((block) => block.label === label)

      if (found.length) {
        // update of section offset in array
        return {
          [section]: sectionState.filter((block) => {
            if (block.label === label) {
              block.el = el
            }
            return block
          }),
        }
      }

      // push of section to array
      return {
        [section]: sectionState.concat({ section, label, el, sectionId }),
      }
    })
  }

  cleanSections = (sectionId) => {
    if (!sectionId) return
    this.setState(({ main }) => ({
      main: main.filter((item) => item.sectionId !== sectionId),
    }))
  }

  static getDerivedStateFromProps(props) {
    if (props.view !== EDIT) {
      return { main: [], advanced: [], extras: [], activeTab: 0 }
    }
    return null
  }

  get isTtsEnabled() {
    const {
      showUserTTS,
      isTestPreviewModalVisible,
      userRole,
      features: { textToSpeech } = {},
      testLevelSettings: { showTextToSpeech } = {},
    } = this.props
    return isTextToSpeechEnabled(
      showTextToSpeech,
      showUserTTS,
      isTestPreviewModalVisible,
      userRole,
      textToSpeech
    )
  }

  /**
   * given a student id and a list ids which are tts users
   * @returns {boolean} whether current student is a tts user
   * For LCB
   */
  get ttsVisibilityAuthorSide() {
    const { studentId, ttsUserIds = [], userRole, data } = this.props
    const key = data?.activity?.userId || studentId
    return userRole !== roleuser.STUDENT && ttsUserIds.includes(key)
  }

  shouldComponentUpdate(prevProps) {
    const {
      data: prevData,
      windowWidth: prevWindowWidth,
      windowHeight: prevWindowHeight,
      userWork: prevUserWork,
      hideCorrectAnswer: prevHideCorrectAnswer,
    } = prevProps
    const {
      data,
      isLCBView,
      isExpressGrader,
      windowWidth,
      windowHeight,
      userWork,
      hideCorrectAnswer,
    } = this.props

    if (
      isLCBView &&
      !isExpressGrader &&
      data?.activity &&
      isEqual(prevUserWork, userWork) &&
      isEqual(prevData, data) &&
      prevWindowHeight === windowHeight &&
      prevWindowWidth === windowWidth &&
      hideCorrectAnswer === prevHideCorrectAnswer
    ) {
      return false
    }
    return true
  }

  componentDidMount() {
    this.setPage(1)
  }

  componentDidUpdate(prevProps) {
    const { isQuestionTypeUpdated } = this.props
    if (
      isQuestionTypeUpdated &&
      isQuestionTypeUpdated !== prevProps.isQuestionTypeUpdated
    ) {
      this.setState({ main: [], advanced: [], extras: [] })
    }
  }

  openStudentWork = () => {
    const { data, loadScratchPad, showStudentWork, isVideoQuiz } = this.props
    // load the data from server and then show
    loadScratchPad({
      testActivityId: data?.activity?.testActivityId,
      testItemId: data?.activity?.testItemId,
      qActId: data?.activity?.qActId || data?.activity?._id,
      isVideoQuiz,
      callback: () => showStudentWork(),
    })
  }

  get advancedAreOpen() {
    const {
      userRole,
      features,
      isPremiumUser,
      isPowerTeacher,
      permissions,
    } = this.props

    const isDistrictAdmin =
      (userRole === TEACHER &&
        !features.isPublisherAuthor &&
        !features.isCurator) ||
      [DISTRICT_ADMIN, SCHOOL_ADMIN].includes(userRole)

    return (
      (isDistrictAdmin && isPowerTeacher && isPremiumUser) ||
      canUseAllOptionsByDefault(permissions, userRole)
    )
  }

  // we will use this method only for LCB and student report
  get renderData() {
    const {
      data,
      studentLanguagePreference,
      view,
      stackedTranslatorContext = {},
    } = this.props

    const { addOriginalQuestion } = stackedTranslatorContext

    return changeDataToPreferredLanguage(
      data,
      studentLanguagePreference,
      view,
      addOriginalQuestion
    )
  }

  get answerScore() {
    const {
      previewScore,
      previewMaxScore,
      testPreviewScore,
      data,
      questions = {},
      multipartItem = false,
      itemLevelScoring = false,
    } = this.props
    let score = previewScore
    let maxScore = previewMaxScore
    let isGradedExternally = false
    if (data?.activity) {
      score = data?.activity?.score
      maxScore = data?.activity.maxScore
      isGradedExternally = data?.activity?.isGradedExternally
      /**
       * @see https://snapwiz.atlassian.net/browse/EV-28499
       * If itemLevelScoring is true score for other questions score is 0
       * Thus awarding a non-zero score to all other questions
       * so that correct responses are not marked wrong, if graded externally
       */
      if (
        isGradedExternally &&
        !isEmpty(questions) &&
        multipartItem &&
        itemLevelScoring
      ) {
        Object.values(questions).forEach((question) => {
          if (question?.activity?.maxScore) {
            score = Math.max(score, question?.activity?.score || 0)
            maxScore = Math.max(maxScore, question?.activity?.maxScore || 0)
          }
        })
      }
    }

    // testPreviewScore is from view as student
    //  {
    //    score: 1,
    //    maxScore: 2,
    //    isGradedExternally: false,
    //  }
    if (testPreviewScore && 'score' in testPreviewScore) {
      score = testPreviewScore.score
      maxScore = testPreviewScore.maxScore
      isGradedExternally = testPreviewScore.isGradedExternally
    }

    return {
      score: (score || 0) / (maxScore || 1),
      isGradedExternally,
      multipartItem,
      itemLevelScoring,
    }
  }

  // @see EV-25152 | need to display show rubric button in student attempt and test review modal
  get showRubricToStudentsButton() {
    const {
      assignmentLevelSettings: { showRubricToStudents = false } = {},
      isTestPreviewModalVisible = false,
      isTestDemoPlayer = false,
      userRole,
      view,
      testLevelSettings: {
        showRubricToStudents: testLevelShowRubricToStudents = false,
      } = {},
      data: { rubrics } = {},
      isPremiumUser,
    } = this.props

    // return if rubric is not attached to the question
    if (isEmpty(rubrics)) {
      return false
    }

    // if test is being viewed in 'view as student' or public test view
    if (
      (userRole === roleuser.TEACHER ||
        roleuser.DA_SA_ROLE_ARRAY.includes(userRole)) &&
      (isTestPreviewModalVisible || isTestDemoPlayer) &&
      isPremiumUser
    ) {
      return testLevelShowRubricToStudents
    }

    // if its a student attempt
    return (
      userRole === roleuser.STUDENT &&
      view === 'preview' &&
      showRubricToStudents
    )
  }

  get isSpeechToTextAllowed() {
    const {
      viewAsStudent,
      assignmentLevelSettings: {
        showSpeechToText: assignmentLevelShowSpeechToText,
      } = {},
      isTestPreviewModalVisible = false,
      isTestDemoPlayer = false,
      userRole,
      testLevelSettings: { showSpeechToText: testLevelShowSpeechToText } = {},
      isPremiumUser,
      accommodations,
      type: qType,
      features,
      view,
    } = this.props

    if (!sttEnabledQuestionTypes.includes(qType)) {
      return false
    }

    const isTestPreview =
      viewAsStudent || isTestDemoPlayer || isTestPreviewModalVisible

    if (userRole === roleuser.STUDENT) {
      return isSpeechToTextEnabled(
        assignmentLevelShowSpeechToText,
        accommodations,
        features?.enableSpeechToText
      )
    }
    if (isTestPreview) {
      if (testLevelShowSpeechToText === undefined && features?.premium) {
        return true
      }
      return testLevelShowSpeechToText
    }
    if (view === PREVIEW) {
      return features?.enableSpeechToText
    }

    return isPremiumUser
  }

  get passageCurrentPage() {
    const {
      userInteractionsPassageData: passageInfo = {},
      data: { id } = {},
    } = this.props
    return passageInfo[id]?.currentPage || 1
  }

  onOpenPrompt = (prompt) => {
    this.setState({
      openedPrompt: prompt,
    })
  }

  updateQuestionStimulus = (node) => {
    let { questionOriginalData: data } = this.props

    const stimulusContainer = getWrapperClarifierSource(node)
    if (!stimulusContainer || !data) return data

    data = cloneDeep(data)

    const primaryLanguageCode = getPrimaryLanguageCode(data)
    const questionLanguageData =
      primaryLanguageCode === LANGUAGE_EN
        ? data
        : data.languageFeatures?.[primaryLanguageCode]

    if (questionLanguageData) {
      const stimulusContainerSource = stimulusContainer.id?.substring(
        'for-clarifier-'.length
      )
      if (/^question-content/.test(stimulusContainerSource)) {
        questionLanguageData.stimulus = stimulusContainer.innerHTML
      }
      if (/^answer-option-/.test(stimulusContainerSource)) {
        const optionId = stimulusContainerSource.replace('answer-option-', '')
        let option = questionLanguageData.options.find(
          ({ value }) => value === optionId
        )
        if (!option) {
          const optionIndex = data.options?.findIndex(
            ({ value }) => value === optionId
          )
          option =
            optionIndex > -1 ? questionLanguageData.options[optionIndex] : {}
        }
        option.label = stimulusContainer.innerHTML
      }
    }
    return data
  }

  canAddMoreClarifier = () => {
    const { data } = this.props

    if (data?.clarifiers?.length >= MAX_ALLOWED_CLARIFIER_PER_QUESTION) {
      return {
        canAdd: false,
        canNotAddReason: `Can not add more than ${MAX_ALLOWED_CLARIFIER_PER_QUESTION} explainers`,
      }
    }
    return { canAdd: true }
  }

  addClarifier = (node, clarifierData) => {
    const { testItemId, addItemClarifier } = this.props
    const updatedQuestionData = this.updateQuestionStimulus(node)
    addItemClarifier({
      testItemId,
      updatedQuestionData,
      clarifierData,
    })
  }

  updateClarifier = (clarifierData) => {
    const { data, testItemId, updateItemClarifier } = this.props
    updateItemClarifier({
      testItemId,
      clarifierData,
      questionId: data.id,
    })
  }

  deleteClarifier = (node, clarifierId) => {
    const { testItemId, deleteItemClarifier } = this.props
    const updatedQuestionData = this.updateQuestionStimulus(node)
    deleteItemClarifier({
      updatedQuestionData,
      testItemId,
      clarifierId,
    })
  }

  getClarifierContent = (id) => {
    const {
      data: { clarifiers },
    } = this.props
    if (clarifiers) {
      return clarifiers.find((clarifier) => clarifier.id === id)?.content
    }
  }

  render() {
    const {
      noPadding,
      isFlex,
      type,
      timespent,
      data: _originalData,
      showFeedback,
      multiple,
      view,
      changePreviewTab,
      qIndex,
      itemIndex,
      windowWidth,
      flowLayout,
      isPresentationMode,
      userRole,
      disableResponse,
      showStudentWork,
      showUserTTS,
      selectedTheme = 'default',
      isPrintPreview = false,
      evaluation,
      loadScratchPad,
      saveHintUsage,
      theme,
      isGrade,
      enableMagnifier,
      playerSkinType = test.playerSkinValues.edulastic,
      isPowerTeacher = false,
      isPremiumUser = false,
      features,
      isItemsVisible,
      permissions,
      questionNumber,
      isPremiumContentWithoutAccess,
      premiumCollectionWithoutAccess,
      showStacked,
      isExpandedView,
      t: translate,
      aiEvaluationStatus,
      authLanguage,
      passageContentChanged = '',
      currentIndex = '',
      updateTestPlayer,
      isStackedTranslationEnabled,
      isGcpsDistrict,
      isClarifierEditable,
      isClarifierViewOnly,
      ...restProps
    } = this.props

    const data = this.renderData

    const _isPowerTeacher =
      isPowerTeacher || canUseAllOptionsByDefault(permissions, userRole)
    const {
      isExpressGrader,
      isStudentReport,
      isLCBView,
      LCBPreviewModal,
      calculatedHeight,
      fullHeight,
      showBorder,
      borderRadius,
      hasDrawingResponse,
      previewTab,
      studentId,
      isQuestionView,
      isShowStudentWork,
      hideCorrectAnswer,
      isReviewTab,
      page: viewPage,
      assignmentLevelSettings: {
        showHintsToStudents: showHintsToStudentsAssignmentLevel = true,
        penaltyOnUsingHints: penaltyOnUsingHintsAssignmentLevel = 0,
        showTtsForPassages: showTtsForPassagesAssignmentLevel = true,
      },
      testLevelSettings: {
        showHintsToStudents: showHintsToStudentsTest = true,
        penaltyOnUsingHints: penaltyOnUsingHintsTest = 0,
        showTtsForPassages: showTtsForPassagesTest = true,
      },
      classLevelSettings,
      viewAsStudent,
    } = restProps

    const showHintsToStudents = viewAsStudent
      ? showHintsToStudentsTest
      : typeof classLevelSettings?.showHintsToStudents === 'boolean'
      ? classLevelSettings.showHintsToStudents
      : showHintsToStudentsAssignmentLevel

    const penaltyOnUsingHints = viewAsStudent
      ? penaltyOnUsingHintsTest
      : typeof classLevelSettings?.penaltyOnUsingHints === 'number'
      ? classLevelSettings.penaltyOnUsingHints
      : penaltyOnUsingHintsAssignmentLevel

    const showTtsForPassages = viewAsStudent
      ? showTtsForPassagesTest
      : typeof classLevelSettings?.showTtsForPassages === 'boolean'
      ? classLevelSettings.showTtsForPassages
      : showTtsForPassagesAssignmentLevel

    const userAnswer = get(data, 'activity.userResponse', null)
    const isSkipped = get(data, 'activity.skipped', false)
    const timeSpent = get(data, 'activity.timeSpent', false)
    const { main, advanced, extras, activeTab, openedPrompt } = this.state
    const page = this.passageCurrentPage

    const disabled =
      get(data, 'activity.disabled', false) || data.scoringDisabled
    const { layoutType } = this.context
    const isPassageOrVideoType = [
      questionType.PASSAGE,
      questionType.VIDEO,
      questionType.TEXT,
    ].includes(data.type)
    const Question =
      isExpressGrader && !isItemsVisible
        ? () => (
            <ItemInvisible
              qLabel={data.qLabel}
              showQuestionNumber={!isPassageOrVideoType && data.qLabel}
            />
          )
        : getQuestion(type)

    const isV1Multipart = get(this.props, 'col.isV1Multipart', false)
    const userAnswerProps = {}
    if (userAnswer && !isSkipped) {
      userAnswerProps.userAnswer = userAnswer
    }

    if (data.id) {
      /**
       * adding `key` forces the component to re-render when `id` changes.
       */
      userAnswerProps.key = data.id
    }

    // EV-36516 | if showTtsForPassages is false hide tts for passage
    const showPlayerForPassage =
      !isStudentReport &&
      (data.type === questionType.PASSAGE || data.type === questionType.VIDEO)
        ? showTtsForPassages
        : true

    const canShowPlayer =
      (this.isTtsEnabled || this.ttsVisibilityAuthorSide) &&
      data.tts &&
      data.tts.taskStatus === 'COMPLETED' &&
      playerSkinType !== test.playerSkinValues.quester &&
      showPlayerForPassage

    /**
     * we need to render the tts buttons at author, if it was rendered at student side
     * however, need to hide the visibility, and not show it in ui
     * need to render it because scratchpad data gets displaced at LCB, EG
     * @see https://snapwiz.atlassian.net/browse/EV-18747
     */
    const hideVisibility =
      isLCBView ||
      isExpressGrader ||
      (userRole === 'teacher' && LCBPreviewModal)

    const studentReportFeedbackVisible =
      isStudentReport && !isPassageOrVideoType && !data.scoringDisabled

    const themeToPass = themes[selectedTheme] || themes.default
    // themeToPass = getZoomedTheme(themeToPass, zoomLevel);
    // themeToPass = playersZoomTheme(themeToPass);

    const showQuestionMenu =
      view === EDIT && windowWidth > parseInt(smallDesktopWidth, 10)

    const advancedLink =
      !this.advancedAreOpen && !showQuestionMenu && advanced.length > 0 ? (
        <AdvancedOptionsLink
          bottom
          isPremiumUser={isPremiumUser}
          isPowerTeacher={_isPowerTeacher}
        />
      ) : null

    const { rubrics: rubricDetails } = data
    const rubricFeedback = data?.activity?.rubricFeedback

    if (isPremiumContentWithoutAccess) {
      return (
        <PremiumItemBanner
          itemBankName={premiumCollectionWithoutAccess}
          showStacked={showStacked}
          data={data}
          isExpandedView={isExpandedView}
          isPrintPreview={isPrintPreview}
          timeSpent={timeSpent}
        />
      )
    }

    let passageRightSpace = {}
    if (
      data.type === questionType.PASSAGE &&
      playerSkinType === test.playerSkinValues.parcc &&
      canShowPlayer
    ) {
      // for not to overlap tts buttons in testNav skin
      passageRightSpace = {
        paddingRight: '60px',
      }
    }

    const answerScore = this.answerScore
    const showAnswerScore =
      isExpressGrader || isLCBView || isReviewTab || viewPage === 'review'

    const aiEvaluationMsg = {
      [AI_EVALUATION_STATUS.PENDING]: {
        text: translate('component.aiEvaluationStatus.pending.text'),
        tooltip: translate('component.aiEvaluationStatus.pending.tooltip'),
      },
      [AI_EVALUATION_STATUS.FAILED]: {
        text: translate('component.aiEvaluationStatus.failed.text'),
        tooltip: translate('component.aiEvaluationStatus.failed.tooltip'),
      },
      [AI_EVALUATION_STATUS.DONE]: {
        text: translate('component.aiEvaluationStatus.done.text'),
        tooltip: translate('component.aiEvaluationStatus.done.tooltip'),
      },
    }

    const hasStackedAudioControls =
      stackedTranslationEnabledQuestionTypes.includes(data?.type) &&
      isStackedTranslationEnabled

    const isParccSkin = playerSkinType === playerSkinValues.parcc

    const showLanguageDropDown =
      hasStackedAudioControls && isParccSkin && !isStudentReport

    const showAudioControlQuestionLevel =
      canShowPlayer && (!hideVisibility || isShowStudentWork) && !isPrintPreview

    const audioControlProps = {
      isShowStudentWork,
      hideVisibility,
      data,
      currentIndex,
      passageContentChanged,
      className: 'question-audio-controller',
      isStudentReport,
      showAudioControlQuestionLevel,
      showLanguageDropDown,
      isParccSkin,
    }

    let aiPrompts = ITEM_TYPE_AND_PROMPT_MAP[data?.title]
    if (aiPrompts) {
      if (authLanguage !== LANGUAGE_EN) {
        aiPrompts = aiPrompts.filter(
          ({ key }) => key !== ITEM_AUTHORING_PROMPTS.QUESTION_GENERATION.key
        )
      }
    }

    return (
      <ThemeProvider
        theme={{
          ...themeToPass,
          fontSize:
            themeToPass.fontSize ||
            getFontSize(get(data, 'uiStyle.fontsize', 'normal')),
          isV1Migrated: data.isV1Migrated,
        }}
      >
        <>
          {view === EDIT && (
            <AIItemAuthoringContainer
              question={data}
              onClose={() => this.setState({ openedPrompt: undefined })}
              prompt={openedPrompt}
            />
          )}
          {showAudioControlQuestionLevel && !hasStackedAudioControls && (
            <AudioControls
              hideVisibility={hideVisibility && !isShowStudentWork}
              /**
               * Appending key with currentIndex and passageContentChanged
               * To fix the issue with audio. proper unmounting required
               * ref: https://goguardian.atlassian.net/browse/EV-40947
               */
              key={`${data.id}_${currentIndex}_${passageContentChanged}`}
              item={data}
              page={page}
              qId={data.id}
              audioSrc={data.tts.titleAudioURL}
              isPaginated={data.paginated_content}
              className="question-audio-controller"
              isStudentReport={isStudentReport}
            />
          )}
          <div
            className="__print-question-main-wrapper"
            style={{ height: !isStudentReport && '100%' }}
          >
            <QuestionContainer
              className={`fr-view question-container question-container-id-${data.testItemId}_${data.id}`}
              disabled={disabled}
              noPadding={noPadding}
              isFlex
              data-cy={
                isPassageOrVideoType ? 'passage-content' : 'question-container'
              }
              style={{
                width: '100%',
                height: calculatedHeight || (fullHeight ? '100%' : null),
                ...passageRightSpace,
              }}
            >
              <ClarifierAccessProvider
                addClarifier={this.addClarifier}
                updateClarifier={this.updateClarifier}
                deleteClarifier={this.deleteClarifier}
                getClarifierContent={this.getClarifierContent}
                isEditable={isClarifierEditable}
                isViewOnly={isClarifierViewOnly}
                containerKey={data.id}
                canAddMoreClarifier={this.canAddMoreClarifier}
              >
                {showQuestionMenu && (
                  <QuestionMenuWrapper>
                    <QuestionMenu
                      activeTab={activeTab}
                      main={main}
                      advanced={advanced}
                      extras={extras}
                      advancedAreOpen={this.advancedAreOpen}
                      questionTitle={data?.title || ''}
                      isPremiumUser={isPremiumUser}
                      isPowerTeacher={_isPowerTeacher}
                    />
                  </QuestionMenuWrapper>
                )}
                <PaperWrapper
                  className="question-wrapper"
                  disabled={disabled}
                  isV1Multipart={isV1Multipart}
                  isStudentReport={isStudentReport}
                  isLCBView={isLCBView}
                  LCBPreviewModal={LCBPreviewModal}
                  borderRadius={isLCBView ? '10px' : borderRadius}
                  style={{
                    width:
                      !isPrintPreview &&
                      `${
                        view === EDIT && showQuestionMenu && !disableResponse
                          ? 'calc(100% - 250px)'
                          : '100%'
                      }`,
                    maxWidth: isPrintPreview && 'calc(100% - 10px)',
                    display: 'flex',
                    boxShadow: 'none',
                    paddingRight: layoutType === COMPACT ? '100px' : null,
                    border: showBorder ? '1px solid #DADAE4' : null,
                  }}
                  flowLayout={
                    type === questionType.CODING && view === 'preview'
                      ? true
                      : flowLayout
                  }
                >
                  <StyledFlexContainer flexDirection="column" maxWidth="100%">
                    {evaluation === 'pending' && (
                      <Tooltip
                        title={translate('component.pendingEvaluation.tooltip')}
                      >
                        <EvaluationMessage>
                          {translate('component.pendingEvaluation.text')}
                        </EvaluationMessage>
                      </Tooltip>
                    )}
                    <EduIf
                      condition={
                        aiEvaluationStatus &&
                        !aiEvaluationStatus?.isGradedExternally
                      }
                    >
                      <FlexContainer>
                        <AiEvaluationWrapper
                          aiEvaluationStatus={aiEvaluationStatus?.status}
                        >
                          <Tooltip
                            title={
                              aiEvaluationMsg[aiEvaluationStatus?.status]
                                ?.tooltip
                            }
                          >
                            <AiEvaluationMessage data-cy="aiEvaluationTag">
                              {
                                aiEvaluationMsg[aiEvaluationStatus?.status]
                                  ?.text
                              }
                            </AiEvaluationMessage>
                          </Tooltip>
                        </AiEvaluationWrapper>
                        <Tooltip title={translate('author:rubric.infoText')}>
                          <FontAwesomeIcon
                            data-cy="aiEvaluationInfoIcon"
                            icon={faInfoCircle}
                            aria-hidden="true"
                            style={{
                              color: 'black',
                              fontSize: '25px',
                              marginLeft: '10px',
                            }}
                          />
                        </Tooltip>
                      </FlexContainer>
                      <EduIf
                        condition={
                          aiEvaluationStatus?.status ===
                          AI_EVALUATION_STATUS.FAILED
                        }
                      >
                        <ManualEvaluationMessage>
                          {translate('component.manualEvaluationNeeded.text')}
                        </ManualEvaluationMessage>
                      </EduIf>
                    </EduIf>
                    <AudioControlProvider
                      {...audioControlProps}
                      hasStackedAudioControls={hasStackedAudioControls}
                    >
                      <ImmersiveReaderWrapper>
                        <Question
                          {...restProps}
                          froalaFooter={
                            aiPrompts?.length && !isGcpsDistrict ? (
                              features?.isVideoQuizAndAIEnabled ? (
                                <AIButtons
                                  prompts={aiPrompts}
                                  onClick={this.onOpenPrompt}
                                />
                              ) : (
                                <AIFeaturesSubscription
                                  isPremiumUser={isPremiumUser}
                                  i18Translate={translate}
                                  showFeatureList
                                />
                              )
                            ) : null
                          }
                          t={translate}
                          item={data}
                          view={view}
                          evaluation={evaluation}
                          answerScore={answerScore}
                          changePreviewTab={changePreviewTab}
                          qIndex={qIndex}
                          advancedLink={advancedLink}
                          advancedAreOpen={this.advancedAreOpen}
                          cleanSections={this.cleanSections}
                          fillSections={this.fillSections}
                          showQuestionNumber={
                            !isPassageOrVideoType && data.qLabel
                          }
                          flowLayout={flowLayout}
                          disableResponse={disableResponse}
                          studentReport={studentReportFeedbackVisible}
                          isPrintPreview={isPrintPreview}
                          {...userAnswerProps}
                          page={page}
                          setPage={this.setPage}
                          showAnswerScore={showAnswerScore}
                          isDefaultTheme={selectedTheme === 'default'}
                          isSpeechToTextEnabled={this.isSpeechToTextAllowed}
                        />
                      </ImmersiveReaderWrapper>
                    </AudioControlProvider>

                    {showFeedback && !isPrintPreview && (
                      <BottomAction
                        view={view}
                        isStudentReport={isStudentReport}
                        hasShowStudentWork={!!showStudentWork}
                        onClickHandler={this.openStudentWork}
                        timeSpent={timeSpent}
                        item={data}
                        QuestionComp={Question}
                        advancedLink={advancedLink}
                        advancedAreOpen={this.advancedAreOpen}
                        hasDrawingResponse={hasDrawingResponse}
                        saveAnswer={restProps.saveAnswer}
                        fillSections={() => {}}
                        cleanSections={() => {}}
                        studentId={studentId}
                        t={translate}
                        isLCBView={isLCBView}
                        isExpressGrader={isExpressGrader}
                        isQuestionView={isQuestionView}
                        previewTab={previewTab}
                        isPrintPreview={isPrintPreview}
                        isGrade={isGrade}
                        data={data}
                        enableMagnifier={enableMagnifier}
                        saveHintUsage={saveHintUsage}
                        isStudent={userRole === 'student'}
                        itemIndex={itemIndex}
                        hideCorrectAnswer={hideCorrectAnswer}
                        isGradedExternally={answerScore.isGradedExternally}
                      />
                    )}
                    {rubricDetails &&
                      studentReportFeedbackVisible &&
                      !isShowStudentWork && (
                        <RubricTableWrapper data-cy="rubricTable">
                          <FieldLabel className="rubric-title">
                            Graded Rubric
                          </FieldLabel>
                          <FieldLabel className="rubric-name">
                            {rubricDetails.name}
                          </FieldLabel>
                          <PreviewRubricTable
                            rubric={rubricDetails}
                            rubricFeedback={rubricFeedback}
                            isDisabled
                          />
                        </RubricTableWrapper>
                      )}
                    {view === 'preview' && !isPrintPreview && !showFeedback && (
                      <Hints
                        question={data}
                        enableMagnifier={enableMagnifier}
                        saveHintUsage={saveHintUsage}
                        isStudent={userRole === 'student'}
                        itemIndex={itemIndex}
                        isLCBView={isLCBView}
                        isExpressGrader={isExpressGrader}
                        isStudentReport={isStudentReport}
                        displayRubricInfoButton={
                          this.showRubricToStudentsButton
                        }
                        rubricDetails={rubricDetails}
                        showHintsToStudents={showHintsToStudents}
                        penaltyOnUsingHints={penaltyOnUsingHints}
                        viewAsStudent={viewAsStudent}
                      />
                    )}
                  </StyledFlexContainer>
                </PaperWrapper>
              </ClarifierAccessProvider>
            </QuestionContainer>
          </div>
        </>
      </ThemeProvider>
    )
  }
}

QuestionWrapper.contextType = ItemDetailContext

QuestionWrapper.propTypes = {
  isPresentationMode: PropTypes.bool,
  view: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  showFeedback: PropTypes.bool,
  type: PropTypes.any,
  isNew: PropTypes.bool,
  data: PropTypes.object,
  saveClicked: PropTypes.bool,
  testItem: PropTypes.bool,
  noPadding: PropTypes.bool,
  changePreviewTab: PropTypes.any,
  isFlex: PropTypes.bool,
  timespent: PropTypes.string,
  qIndex: PropTypes.number,
  windowWidth: PropTypes.number.isRequired,
  flowLayout: PropTypes.bool,
  userRole: PropTypes.string.isRequired,
  disableResponse: PropTypes.bool,
  clearAnswers: PropTypes.func,
  saveHintUsage: PropTypes.func,
  LCBPreviewModal: PropTypes.any,
  permissions: PropTypes.array,
  isTestDemoPlayer: PropTypes.bool,
  t: PropTypes.func,
}

QuestionWrapper.defaultProps = {
  isNew: false,
  type: null,
  data: {},
  saveClicked: false,
  testItem: false,
  noPadding: false,
  isFlex: false,
  timespent: '',
  multiple: false,
  LCBPreviewModal: false,
  showFeedback: false,
  qIndex: 0,
  clearAnswers: () => {},
  changePreviewTab: () => {},
  flowLayout: false,
  saveHintUsage: () => {},
  disableResponse: false,
  isPresentationMode: false,
  permissions: [],
  isTestDemoPlayer: false,
  t: () => {},
}

const enhance = compose(
  React.memo,
  withWindowSizes,
  withAnswerSave,
  withTheme,
  withStackedTranslator,
  withNamespaces(['assessment', 'author']),
  connect(
    (state, ownProps) => ({
      isPresentationMode: get(
        state,
        ['author_classboard_testActivity', 'presentationMode'],
        false
      ),
      showUserTTS: getAccommodationsTtsSelector(state),
      selectedTheme: state.ui.selectedTheme,
      zoomLevel: state.ui.zoomLevel,
      userRole: getUserRole(state),
      enableMagnifier: state.testPlayer.enableMagnifier,
      playerSkinType: playerSkinTypeSelector(state),
      isPowerTeacher: get(state, ['user', 'user', 'isPowerTeacher'], false),
      isPremiumUser: get(state, ['user', 'user', 'features', 'premium'], false),
      permissions: get(state, 'user.user.permissions', []),
      features: getUserFeatures(state),
      isItemsVisible: isItemVisibiltySelector(state),
      ttsUserIds: ttsUserIdSelector(state),
      studentLanguagePreference: languagePreferenceSelector(state, ownProps),
      authLanguage: getCurrentLanguage(state),
      isTestPreviewModalVisible: getIsPreviewModalVisibleSelector(state),
      previewScore: state?.itemScore?.score, // this used only in the author preview
      previewMaxScore: state?.itemScore?.maxScore, // this used only in the author preview
      assignmentLevelSettings: assignmentLevelSettingsSelector(state),
      testLevelSettings: get(state, ['test', 'settings'], {}),
      userInteractionsPassageData: get(
        state,
        ['userInteractions', 'passages'],
        {}
      ),
      isVideoQuiz: getIsVideoQuizSelector(state),
      accommodations: getUserAccommodations(state),
      currentIndex: currentItemIndexSelector(state),
      passageContentChanged: state.testPlayer?.passageContentChanged,
      isStackedTranslationEnabled: isStackedTranslationEnabledSelector(state),
      isGcpsDistrict: isGcpsDistrictSelector(state, true),
    }),
    {
      loadScratchPad: requestScratchPadAction,
      setPassageCurrentPage: setPassageCurrentPageAction,
      changedPassageContent: changedPassageContentAction,
      addItemClarifier: addItemClarifierAction,
      updateItemClarifier: updateItemClarifierAction,
      deleteItemClarifier: deleteItemClarifierAction,
    }
  )
)

export default enhance(QuestionWrapper)
