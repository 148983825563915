import React from 'react'
import { EduButton, FlexContainer } from '@edulastic/common'
import { ConfirmationModal } from '../../../src/components/common/ConfirmationModal'

const ConfirmCloneOnEdit = ({
  cloneProceed,
  cloneModalType,
  cloneModalVisible,
  cloneModalClose,
}) => {
  return (
    <ConfirmationModal
      title={
        <p style={{ fontSize: '22px' }}>
          Sorry, you don&apos;t have edit rights. Please read below.
        </p>
      }
      visible={cloneModalVisible}
      textAlign="left"
      destroyOnClose
      onCancel={cloneModalClose}
      centered
      padding="25px 60px"
      modalWidth="800px"
      footer={[
        <EduButton
          height="40px"
          isGhost
          key="cancelButton"
          onClick={cloneModalClose}
        >
          CANCEL
        </EduButton>,
        <EduButton height="40px" key="okButton" onClick={cloneProceed}>
          CLONE TEST
        </EduButton>,
      ]}
    >
      <FlexContainer flexDirection="column" gap="20px" justifyContent="center">
        {cloneModalType === 'OutDistrict' ? (
          <div style={{ fontWeight: 'bold' }}>
            You don&apos;t have permission to edit this test, and the test
            author is outside your district, however you can clone it to create
            your own editable version.
          </div>
        ) : (
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              You don&apos;t have permission to edit this test, but you can
              create a clone of it. Here are your options:
            </div>
            <ul>
              <li>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Clone the test</span> to
                  make your own editable version and regrade your assignment, if
                  any.
                </div>
              </li>
              <li>
                <div>
                  <span style={{ fontWeight: 'bold' }}>
                    Request edit permissions
                  </span>{' '}
                  from the test author to modify the original test.
                </div>
              </li>
            </ul>
          </div>
        )}
        <div>
          <span style={{ fontWeight: 'bold' }}>Please note:</span> If the
          original test is assigned across multiple teachers&apos; classes as a
          common assignment, student scores from the original and cloned tests
          won&apos;t appear together in admin reports, which may cause confusion
          for administrators.
        </div>
      </FlexContainer>
    </ConfirmationModal>
  )
}

export default ConfirmCloneOnEdit
