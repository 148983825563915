import {
  CheckboxLabel,
  CustomModalStyled,
  EduButton,
  RadioBtn,
  RadioGrp,
  SelectInputStyled,
  notification,
} from '@edulastic/common'
import { userPermissions, roleuser, subscriptions } from '@edulastic/constants'
import { Col, Form, Row, Select, Tooltip } from 'antd'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { ButtonsContainer } from '../../../../../common/styled'
import { CheckBoxSubLabel, EnableDisableContainer } from '../styled'
import { getSubscriptionSelector } from '../../../../Subscription/ducks'

const { DISTRICT_COMMON, SCHOOL_COMMON } = userPermissions
const BulkEditTeacherModal = (props) => {
  const {
    form,
    bulkEditTeacher,
    userOrgId,
    closeModal,
    loadDistrictTestSettings,
    bulkEditTeacherKeys,
    modalVisible,
    userSearchQuery,
    form: { getFieldDecorator, getFieldValue },
    t,
    districtTestSettings,
    userRole,
    subscription: { subType } = {},
  } = props

  const showDistrictCommon = userRole !== roleuser.SCHOOL_ADMIN

  const onSaveTeacher = () => {
    form.validateFields((err, row = {}) => {
      if (!err) {
        const data = { users: bulkEditTeacherKeys }
        let error = ''
        if (!row.updatePermissions && !row.updatePowerTools) {
          error = 'Select atleast one settings to update.'
        }
        if (row.updatePermissions) {
          if (
            row.enablePermissions === undefined ||
            (row.enablePermissions && row.permissions === undefined)
          ) {
            error = 'Please select common permissions settings.'
          } else {
            data.commonPermissions = {
              enabled: row.enablePermissions,
              permissions: row.enablePermissions
                ? row.permissions === DISTRICT_COMMON
                  ? [DISTRICT_COMMON, SCHOOL_COMMON]
                  : [SCHOOL_COMMON]
                : [],
            }
          }
        }
        if (row.updatePowerTools) {
          if (row.isPowerTeacher === undefined) {
            error = 'Please select advanced authoring setting.'
          } else {
            data.powerTeacherEnabled = row.isPowerTeacher
          }
        }
        if (error) {
          notification({
            type: 'warning',
            msg: error,
          })
          return
        }
        bulkEditTeacher({ data, userSearchQuery })
        closeModal()
      }
    })
  }

  useEffect(() => {
    loadDistrictTestSettings({
      orgId: userOrgId,
      orgType: 'district',
    })
  }, [])

  const commonSettingDisabled =
    !districtTestSettings.canTeacherAuthorCommonTests ||
    subType !== subscriptions.SUBSCRIPTION_SUB_TYPES.ENTERPRISE
  const disablePermissionRadioButtons =
    commonSettingDisabled || !getFieldValue('updatePermissions')
  return (
    <CustomModalStyled
      visible={modalVisible}
      title={`Update ${bulkEditTeacherKeys.length} Selected Users`}
      onOk={onSaveTeacher}
      onCancel={closeModal}
      maskClosable={false}
      centered
      footer={[
        <ButtonsContainer>
          <EduButton isGhost onClick={closeModal}>
            {t('users.teacher.editteacher.nocancel')}
          </EduButton>
          <EduButton onClick={onSaveTeacher}>
            {t('users.teacher.editteacher.yesupdate')}
          </EduButton>
        </ButtonsContainer>,
      ]}
    >
      <Row>
        <Col span={24} style={{ marginBottom: '20px' }}>
          {getFieldDecorator('updatePowerTools', {
            initialValue: false,
            valuePropName: 'checked',
          })(
            <CheckboxLabel
              data-cy="powerUserCheckBox"
              textTransform="none"
              labelFontSize="14px"
            >
              Update Advanced Authoring (Power Tools)
              <CheckBoxSubLabel>
                Advanced settings while authoring questions
              </CheckBoxSubLabel>
            </CheckboxLabel>
          )}
          <EnableDisableContainer>
            {getFieldDecorator('isPowerTeacher')(
              <RadioGrp>
                <RadioBtn
                  value
                  disabled={!getFieldValue('updatePowerTools')}
                  textTransform="none"
                  data-cy="powerUserEnableRadioButton"
                  labelFontSize="14px"
                >
                  Enable
                </RadioBtn>
                <RadioBtn
                  value={false}
                  disabled={!getFieldValue('updatePowerTools')}
                  textTransform="none"
                  data-cy="powerUserDisableRadioButton"
                  labelFontSize="14px"
                >
                  Disable
                </RadioBtn>
              </RadioGrp>
            )}
          </EnableDisableContainer>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {getFieldDecorator('updatePermissions', {
            initialValue: false,
            valuePropName: 'checked',
          })(
            <CheckboxLabel
              data-cy="updateCommonTestPermissions"
              textTransform="none"
              disabled={commonSettingDisabled}
              labelFontSize="14px"
            >
              Update Common Test Permissions
              <CheckBoxSubLabel>
                Allow teacher to publish and regrade common test{' '}
              </CheckBoxSubLabel>
            </CheckboxLabel>
          )}
          <EnableDisableContainer>
            {getFieldDecorator('enablePermissions')(
              <RadioGrp>
                <RadioBtn
                  value
                  disabled={disablePermissionRadioButtons}
                  textTransform="none"
                  data-cy="commonTestPermissionsEnableRadioButton"
                  labelFontSize="14px"
                >
                  Enable
                </RadioBtn>
                <RadioBtn
                  value={false}
                  disabled={disablePermissionRadioButtons}
                  textTransform="none"
                  data-cy="commonTestPermissionsDisableRadioButton"
                  labelFontSize="14px"
                >
                  Disable
                </RadioBtn>
              </RadioGrp>
            )}
          </EnableDisableContainer>
          {getFieldValue('updatePermissions') &&
            getFieldValue('enablePermissions') && (
              <div style={{ marginLeft: '30px', marginTop: '10px' }}>
                <span
                  style={{
                    marginRight: '20px',
                    fontWeight: 600,
                    fontSize: '14x',
                  }}
                >
                  Available Test Type(s)
                </span>
                {getFieldDecorator('permissions')(
                  <SelectInputStyled
                    data-cy="permissions-value"
                    showSearch
                    optionFilterProp="children"
                    mode="default"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    margin="0px 0px 15px"
                    width="200px"
                  >
                    <Select.Option
                      key={DISTRICT_COMMON}
                      value={DISTRICT_COMMON}
                      disabled={!showDistrictCommon}
                    >
                      <Tooltip
                        title={
                          userRole !== roleuser.DISTRICT_ADMIN
                            ? t('users.teacher.districtCommonInfo')
                            : ''
                        }
                      >
                        District & School Common
                      </Tooltip>
                    </Select.Option>

                    <Select.Option key={SCHOOL_COMMON} value={SCHOOL_COMMON}>
                      Only School Common
                    </Select.Option>
                  </SelectInputStyled>
                )}
              </div>
            )}
        </Col>
      </Row>
    </CustomModalStyled>
  )
}

const BulkEditTeacherModalForm = Form.create()(BulkEditTeacherModal)
const enhance = connect((state) => ({
  subscription: getSubscriptionSelector(state),
}))

export default enhance(BulkEditTeacherModalForm)
