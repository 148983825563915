import React, { useMemo } from 'react'
import { debounce, get as _get } from 'lodash'
import { Empty, Select, notification } from 'antd'
import { connect } from 'react-redux'
import { SelectInputStyled } from '@edulastic/common'
import { SHARE_ENTITY_TYPE } from '@edulastic/constants/const/common'
import { roleuser } from '@edulastic/constants'
import { IconWarnTriangle } from '@edulastic/icons'
import {
  fetchUsersListAction,
  updateUsersListAction,
  getUsersListSelector,
} from '../../../../../sharedDucks/userDetails'
import { deleteSharedUserAction, sendTestShareAction } from '../../../../ducks'
import { PermissionsWarningContainer } from './styled'

const { Option } = Select

const { TEST } = SHARE_ENTITY_TYPE
const paranthesisRegex = /\(([^)]+)\)/ // Matches anything inside parentheses

const AdminCoAuthorSelection = ({
  adminCoAuthors = [],
  getUsers,
  updateShareList,
  userList,
  currentUserId,
  testId,
  shareTest,
  deleteSharedUser,
  showPermissionWarning,
}) => {
  const searchUser = debounce((value) => {
    const searchBody = {
      limit: 10,
      page: 1,
      type: 'INDIVIDUAL',
      search: {
        role: ['school-admin', 'district-admin'],
        searchString: value,
        status: 1,
      },
    }
    getUsers(searchBody)
  }, 500)

  const handleSearch = (value) => {
    if (value.length > 1) {
      searchUser(value)
    } else {
      updateShareList({ data: [] })
    }
  }

  const selectShare = (id) => {
    const currentUser = userList.find((people) => id === people._id)
    const isExisting = adminCoAuthors.some(
      (item) => item._id === currentUser._id
    )
    if (!Object.keys(currentUser).length) return
    let person = {}
    if (isExisting) {
      notification({ messageKey: 'userHasPermission' })
      return
    }
    const { firstName, lastName, email, role } = currentUser._source
    person = {
      sharedWith: [
        { _id: currentUser._id, name: `${firstName} ${lastName}`, email },
      ],
    }

    const data = {
      ...person,
      emails: [],
      sharedType: 'INDIVIDUAL',
      permission: 'EDIT',
      contentType: TEST,
    }
    shareTest({
      data,
      contentId: testId,
      adminAuthorInfo: {
        firstName,
        lastName,
        email,
        role,
        _id: currentUser._id,
      },
    })
  }

  const deselectTags = (value) => {
    try {
      const match = value.match(paranthesisRegex)
      if (match) {
        const email = match[1]
        const deleteUser = adminCoAuthors.find((p) => p.email === email)

        if (deleteUser) {
          deleteSharedUser({
            contentId: testId,
            sharedWith: deleteUser._id,
            contentType: TEST,
          })
        }
      }
    } catch {
      notification('The admin author removal failed.')
    }
  }

  const _selectedAdmins = useMemo(
    () =>
      adminCoAuthors.map(
        (t) =>
          `${t.firstName} ${t.lastName} (${t.email}), [${
            roleuser.ROLE_LABEL[t.role]
          }]`
      ),
    [adminCoAuthors]
  )
  const filteredUserList = userList.filter(
    (user) =>
      adminCoAuthors.every((people) => user._id !== people._id) &&
      user._id !== currentUserId
  )
  return (
    <>
      <SelectInputStyled
        showArrow
        data-cy="adminCoAuthorSelect"
        className="adminCoAuthorSelect"
        mode="multiple"
        optionLabelProp="title"
        placeholder="Search/Select Admin"
        value={_selectedAdmins}
        onSearch={handleSearch}
        onSelect={selectShare}
        onDeselect={deselectTags}
        filterOption={false}
        getPopupContainer={(trigger) => trigger.parentNode}
        notFoundContent={
          <Empty
            className="ant-empty-small"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ textAlign: 'left', margin: '10px 0' }}
            description="No matching results"
          />
        }
        style={{ width: '500px' }}
      >
        {filteredUserList.map((item) => (
          <Option value={item._id} key={item._id}>
            <span>
              {`${item._source.firstName} ${item._source.lastName}`}
              {`(${item._source.email})`}{' '}
              <b>{`[${roleuser.ROLE_LABEL[item._source.role]}]`}</b>
            </span>
          </Option>
        ))}
      </SelectInputStyled>
      {showPermissionWarning && (
        <PermissionsWarningContainer>
          <IconWarnTriangle />
          <p>
            You don’t have permission to publish{' '}
            {showPermissionWarning === 'common assessment'
              ? 'district common'
              : 'school common'}{' '}
            test. Reach out to your{' '}
            {showPermissionWarning === 'common assessment'
              ? 'District Admin '
              : 'District/School Admin '}
            for common test permissions or add and ask co-author admin to
            publish the test
          </p>
        </PermissionsWarningContainer>
      )}
    </>
  )
}

export default connect(
  (state) => ({
    userList: getUsersListSelector(state),
    currentUserId: _get(state, 'user.user._id', ''),
  }),
  {
    getUsers: fetchUsersListAction,
    updateShareList: updateUsersListAction,
    shareTest: sendTestShareAction,
    deleteSharedUser: deleteSharedUserAction,
  }
)(AdminCoAuthorSelection)
