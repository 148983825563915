import React, { useEffect, useState } from 'react'
import uuid from 'uuid'
import { getSelectionRect, helpers, notification } from '@edulastic/common'
import AddClarifierPopover from './AddClarifierPopover'
import AddEditClarifierContentModal from './AddEditClarifierContentModal'
import useTextSelect from '../hooks/useTextSelect'
import {
  getPopoverStyles,
  isClarifierElement,
  isSelectionOverlappingClarifier,
} from '../utils'
import ViewClarifierPopover from './ViewClarifierPopover'
import DeleteClarifierContentModal from './DeleteClarifierContentModal'
import { CLARIFIER_QUERY_SELECTOR } from '../constants'
import { useClarifierAccessContext } from './ClarifierAccessProvider'

const ACTION_ADD = 'add'
const ACTION_EDIT = 'edit'
const ACTION_VIEW = 'view'

const ClarifiersActionHandler = ({
  addClarifier,
  updateClarifier,
  deleteClarifier,
  getClarifierContent,
  isEditable,
  isViewOnly,
  containerRef,
  canAddMoreClarifier,
}) => {
  const [
    { modal: openModal, content: modalContent, ...visibleModalData },
    setVisibleModalData,
  ] = useState({})
  const [visiblePopoverData, setVisiblePopoverData] = useState({})
  const [selectedClariferId, setSelectedClariferId] = useState()
  const { getAllowedElements } = useClarifierAccessContext()
  const { selectedText, range, selection, setSelection } = useTextSelect({
    clarifierAllowedElements: getAllowedElements(),
  })

  const getRangeContentAsHtml = (selectionRange = range) => {
    return helpers.sanitizeForReview(selectionRange?.cloneContents() || '')
  }

  const handleHidePopover = () => setVisiblePopoverData({})

  const handleCloseModal = () => {
    handleHidePopover()
    setVisibleModalData({})
    setSelectedClariferId()
    window.getSelection()?.removeAllRanges()
  }

  const handleShowAddModal = () => {
    const { canAdd, canNotAddReason } = canAddMoreClarifier()
    if (!canAdd) {
      notification({
        type: 'warning',
        msg: canNotAddReason,
      })
      return
    }

    setVisibleModalData({
      modal: ACTION_ADD,
      range,
      selectionStimulus: getRangeContentAsHtml(),
    })
  }

  const handleShowEditModal = () => {
    setVisibleModalData({
      modal: ACTION_EDIT,
      id: selectedClariferId,
      selectionStimulus: getRangeContentAsHtml(),
      content: visiblePopoverData.content,
    })
  }

  const handleShowDeleteModal = () => {
    setVisibleModalData({
      modal: 'delete',
      id: selectedClariferId,
      range,
    })
  }

  const handleAddClarifier = () => {
    const newNode = document.createElement('span')
    newNode.id = uuid()
    newNode.setAttribute('data-content-support', 'clarifier')
    newNode.classList.add('content-clarifier')

    const extractedContents = visibleModalData.range.extractContents()
    newNode.appendChild(extractedContents)
    visibleModalData.range.insertNode(newNode)

    addClarifier(visibleModalData.range, {
      id: newNode.id,
      content: modalContent,
    })
  }

  const handleSaveClarifier = () => {
    updateClarifier({
      id: selectedClariferId,
      content: modalContent,
    })
  }

  const handleDeleteClarifier = () => {
    const clarifierSpan = range.commonAncestorContainer
    const fragment = range.extractContents()
    clarifierSpan.parentNode.replaceChild(fragment, clarifierSpan)

    deleteClarifier(range, selectedClariferId)
  }

  const popoverStyles = () => {
    const textSelectedPosition = getSelectionRect(window)
    return getPopoverStyles({
      windowWidth: window.innerWidth,
      selectionTop: textSelectedPosition.top,
      selectionLeft: textSelectedPosition.left,
      selectionWidth: textSelectedPosition.width,
      selectionHeight: textSelectedPosition.height,
    })
  }

  const viewable = isEditable || isViewOnly
  useEffect(() => {
    if (viewable) {
      const click = (event) => {
        const closestClarifierSpan = event.target.closest(
          CLARIFIER_QUERY_SELECTOR
        )

        if (closestClarifierSpan) {
          const nodeRange = new Range()
          nodeRange.selectNodeContents(closestClarifierSpan)

          const currentSelection = window.getSelection()
          currentSelection.removeAllRanges()
          currentSelection.addRange(nodeRange)
          setSelection(currentSelection)
        }
      }

      containerRef.current.addEventListener('click', click)
      return () => {
        containerRef.current.removeEventListener('click', click)
      }
    }
  }, [viewable])

  useEffect(() => {
    visiblePopoverData.styles &&
      setVisiblePopoverData((data) => ({
        ...data,
        styles: popoverStyles(),
      }))
  }, [window.innerWidth, window.innerHeight])

  useEffect(() => {
    if (selection) {
      const selectionRange = selection.getRangeAt(0)
      const selectionText = selection.toString()

      if (
        selectionRange &&
        isClarifierElement(selectionRange.commonAncestorContainer)
      ) {
        const selectedClarifierId = selectionRange.commonAncestorContainer.id

        setSelectedClariferId(selectedClarifierId)
        if (viewable) {
          setVisiblePopoverData({
            popover: ACTION_VIEW,
            id: selectedClarifierId,
            styles: popoverStyles(),
            content: getClarifierContent(selectedClarifierId),
            clarifierTitle: getRangeContentAsHtml(selectionRange),
          })
        }
        return
      }

      if (
        selectionText?.trim() &&
        !isSelectionOverlappingClarifier(selection)
      ) {
        isEditable &&
          setVisiblePopoverData({
            popover: ACTION_ADD,
            styles: popoverStyles(),
          })
      }
    } else if (!selectedText?.trim()) {
      handleHidePopover()
    }
  }, [selection])

  return (
    <>
      {isEditable && visiblePopoverData?.popover === ACTION_ADD && (
        <AddClarifierPopover
          styles={visiblePopoverData.styles}
          onAddClarifier={() => {
            handleHidePopover()
            handleShowAddModal()
          }}
        />
      )}

      {visiblePopoverData?.popover === ACTION_VIEW && (
        <ViewClarifierPopover
          styles={visiblePopoverData.styles}
          content={visiblePopoverData.content}
          onClickEdit={() => {
            handleHidePopover()
            handleShowEditModal()
          }}
          onClickRemove={() => {
            handleHidePopover()
            handleShowDeleteModal()
          }}
          selectedTextTitle={visiblePopoverData.clarifierTitle}
          isEditable={isEditable}
        />
      )}

      {(openModal === ACTION_ADD || openModal === ACTION_EDIT) && (
        <AddEditClarifierContentModal
          visible
          onCloseModal={handleCloseModal}
          isAdd={openModal === ACTION_ADD}
          onSave={() => {
            openModal === ACTION_ADD
              ? handleAddClarifier()
              : handleSaveClarifier()
            handleCloseModal()
          }}
          selectionStimulus={visibleModalData.selectionStimulus}
          onEditorChange={(val) => {
            setVisibleModalData((data) => ({ ...data, content: val }))
          }}
          content={modalContent}
        />
      )}
      {openModal === 'delete' && (
        <DeleteClarifierContentModal
          onDelete={() => {
            handleDeleteClarifier()
            handleCloseModal()
          }}
          onCancel={handleCloseModal}
        />
      )}
    </>
  )
}

export default ClarifiersActionHandler
